import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import posthog from 'posthog-js';
import { filter } from "rxjs";
import { MetricsService } from "./metrics.service";


@Injectable()
export class PosthogMetrics extends MetricsService {

    private uuidPattern: RegExp = new RegExp('[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}', 'g');

    constructor(router: Router) {
        super();
        this.capturePageViews(router);
    }

    private capturePageViews(router: Router) {
        router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            // remove all UUID's and provide a sanitised URL for better matching.
            const url = event.urlAfterRedirects.replace(this.uuidPattern, '-');
            this.capture('$pageview', {
                'sanitised_url': url
            });
        });
    }

    capture(eventName: string, payload?: unknown): void {
        posthog.capture(eventName, payload);
    }

    reset(): void {
        posthog.reset();
    }

}
