@if (isMobile()) {
    <kui-card-header>
        <ul class="content-card-actions">
          <li (click)="onClosePanel()">
            <kui-icon kui-action name="times"></kui-icon>
          </li>
        </ul>
    </kui-card-header>
}

<kui-accordion class="flex-1">

    @for (filter of filters; track filter.id) {
        <key-filter-section
            [title]="filter.title"
            [expanded]="!filter.collapsed">
            @switch (filter.type) {
                @case('checkbox') {
                    <key-checkbox-filter [config]="filter"></key-checkbox-filter>
                }
                @case('radio') {
                    <key-radio-filter [config]="filter"></key-radio-filter>
                }
                @case('tree') {
                    <key-tree-filter [config]="filter"></key-tree-filter>
                }
            }
        </key-filter-section>
    }

</kui-accordion>