@if (showDateSelect) {
    <key-date-range-select
        [headerActions]="headerActions()"
        [timePeriod]="datePeriod()"
        [maxDate]="defaultMaxDate"
        (onDateSelection)="onDateSelect($event)">
    </key-date-range-select>
}

@if (videoListing.length === 0 && listLoaded) {
    <kui-alert type="info"
        class="key-alert-wrapper">
        <kui-icon name="lightbulb"
            size="sm"></kui-icon>
        <span>{{ noVideosMessage ? (noVideosMessage | translate): 'VIDEOS.LIST.NO_VIDEOS' | translate }}</span>
    </kui-alert>

    @if (!noVideosMessage) {
        <div class="custom-message-container">
            <div class="d-flex">
                <span>{{ 'VIDEOS.LIST.NO_VIDEOS_DESC1' | translate }}</span>
            </div>
    
            <div class="d-flex">
                <span>{{ 'VIDEOS.LIST.NO_VIDEOS_DESC2' | translate }}</span>
            </div>
            <button class="mt-3 ml-auto mr-auto"
                kui-button
                color="primary"
                (click)="requestVideo.emit()">
                <kui-icon
                    class="mr-2"
                    name="video"></kui-icon><span>{{ 'DIALOG.VIDEO' | translate }}</span>
            </button>
        </div>
    }
}


<div key-content-container
    class="d-flex"
    [scroll]="true"
    #contentContainer>

    @if (!listLoaded && !errorMessage) {
        <div class="loading-overlay d-flex flex-stretch justify-content-center align-items-center">
            <kui-loader>
            </kui-loader>
        </div>
    }

    <kui-alert-panel [message]="errorMessage"></kui-alert-panel>

    @if (!errorMessage && listLoaded) {
        <div class="flex-1">
            @for (video of videoListing; track video.id) {
                <key-video-list-item
                    [class.selected]="video.id === selectedId"
                    [data]="video"
                    [showFilterByValue]="showFilterByValue"
                    (selected)="itemSelected.emit($event)">
                </key-video-list-item>
            } 
        </div>
    }
</div>

@if (count) {
    <kui-content-header
        [custom]="true"
        [sub]="true"
        class="w-100">

        @if (pageCount > 1) {
            <kui-paginate
                class="ml-3"
                [page]="page"
                [total]="count"
                [perPage]="limit"
                [collapse]="true"
                (pageChanged)="onPageChanged($event)"></kui-paginate>
            }

        <div class="ml-auto pr-3">
            {{ count === 1 ? ('STATUS.LIST.ONE_ITEM' | translate) : ('STATUS.LIST.TOTAL_ITEMS' | translate: { total: count }) }}
        </div>

    </kui-content-header>
}
