@if (showLabelListSpinner) {
    <span class="badge badge-secondary disabled">
        <kui-loader [size]="'small'"></kui-loader>
        @if (labelMenu().length > 0) {
            <span class="ml-1">{{ 'SHARED.ADD' | translate }}</span>
        }
        
        @else {
            <span class="ml-1">{{ 'SHARED.LOADING' | translate }}</span>
        }
    </span>
}

@else {

    @if (labelMenu().length > 0) {
        <kui-dropdown
            [style]="'none'"
            [title]="'SHARED.ADD' | translate"
            [menu]="labelMenu()">
            <span class="badge badge-secondary">
                <kui-icon name="plus" class="mr-1"></kui-icon>
                {{ 'SHARED.ADD' | translate }}
            </span>
        </kui-dropdown>
    }

    @else {
    <kui-dropdown
        [style]="'none'"
        [title]="'SHARED.ADD' | translate"
        [customContent]="nolabels">
        <span class="badge badge-secondary">
            <kui-icon name="plus" class="mr-1"></kui-icon>
            {{ 'SHARED.ADD' | translate }}
        </span>
    </kui-dropdown>
    }
}

<ng-template #nolabels>
    <span class="no-labels">No labels available. Labels can be created in the {{ 'MENU.ADMINISTRATION' | translate}} view.</span>
</ng-template>