import posthog from "posthog-js";

export const initialiseMetrics = (key: string, host: string) => {
    posthog.init(
        key,
        {
            api_host: host,
            person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
        }
    );
};