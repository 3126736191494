<div class="position-relative">
    <span kui-action
        class="position-absolute w-100 h-100"
        [class.focussed]="isFocussed">

        <p-calendar *ngIf="!disabled"
            panelStyleClass="kui-datetime"
            [(ngModel)]="dateInstance"
            (onSelect)="update($event)"
            (onFocus)="setFocussedState(true)"
            (onClose)="setFocussedState(false)"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="yearRange"
            [minDate]="minInstance"
            [maxDate]="maxInstance"
            [showTime]="type === 'datetime-local'"
            [showSeconds]="type === 'datetime-local'"
            [timeOnly]="type === 'time'"
            [showIcon]="true"
            appendTo="body">
        </p-calendar>
    </span>

    <label (click)="setFocussedState(true)"
        class="input-group mb-0 d-flex align-items-center"
        [for]="'datetimepicker-' + id">
        <span [class.form-control]="inputStyle === 'normal'"
            [class.disabled]="disabled">{{date | dateTimeFormat: format : type || ('SHARED.NONE' | translate) }}</span>
        <kui-icon *ngIf="icon !== 'none'"
            [name]="icon || type === 'time' ? 'clock' : 'calendar'"
            [ngClass]="{
        'input-group-addon': inputStyle === 'normal',
        'align-self-stretch': inputStyle === 'normal',
        'ml-2': inputStyle === 'none'
      }"></kui-icon>
    </label>
</div>