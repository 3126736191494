import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

/*
 * Converts datetime to local time
 *
 * Usage:
 *   value | localizeTime
 */
@Pipe({ name: 'localizeTime' })
export class LocalizeTimePipe implements PipeTransform {
    transform(value: string | Date, format: string): string {
        return moment(value).format(format);
    }
}
