import { FormBuilderField } from 'app/shared/components/form-builder';
import { FeatureSection } from 'app/app.features';

export const BACKEND_FEATURE_FLAGS = 'fleet-backend-v2';

export const BackendFeatureDefaults = {
    // When adding a feature flag that needs to make it's way to the admin features page, add it to the following 
    // structure. Values added here are automatically parsed to create editors in Administration.
    // NOTE: you'll also need to add translation text in the `en.json` file!
    geocoding: {
        roadspeed: {
            enabled: false,
            radiusRoads: 100,
        },
    },
    shifts: {
        _only: ['system'],
        shifts: {
            enabled: false,
        },
    },
    parsing: {
        _only: ['system'],
        native: {
            enabled: false,
        },
        linking: {
            enabled: false,
        },
        fnol: {
            enabled: false,
        },
        v2tripcalculation: {
            enabled: false,
        }
    },
    tasks: {
        _only: ['system'],
        taskservice: {
            enabled: false,
        },
    },
    reports: {
        _only: ['system'],
        new_processor: {
            enabled: false,
        },
        new_renderer: {
            enabled: false,
        },
    },
    privacy: {
        _only: ['system'],
        trip_privacy: {
            enabled: false,
        },
    }
};

export interface BackendFeatureFlags {
    [section: string]: BackendFeatureSection;
}

const ICONS = {
    geocoding: 'map-marked',
    shifts: 'business-time',
    parsing: 'cogs',
    tasks: 'tasks',
    reports: 'chart-pie',
    privacy: 'user-secret',
};


export class BackendFeatureSection implements FeatureSection {

    icon = ICONS[this.page];
    fields: FormBuilderField[] = [];
    defaults = {};

    constructor(public page: string) {
    }

    private addDefault(id: string, defaultValue: any) {
        this.defaults[`${this.page}-${id}`] = defaultValue;
    }

    addEnabledToggle(id: string, defaultValue: boolean): BackendFeatureSection {
        this.addDefault(id, defaultValue);
        this.fields.push({
            id: `${this.page}-${id}`,
            title: id.toUpperCase(),
            type: 'combo',
            required: true,
            values: [
                { key: 'null', value: 'INHERIT' },
                { key: 'true', value: 'ENABLED' },
                { key: 'false', value: 'DISABLED' },
            ],
        });
        return this;
    }

    addNumber(id: string, min: number, max: number, defaultValue: number): BackendFeatureSection {
        this.addDefault(id, defaultValue);
        this.fields.push({
            id: `${this.page}-${id}`,
            title: id.toUpperCase(),
            type: 'number',
            required: false,
            min: min,
            max: max,
        } as any);
        return this;
    }

}



export function getBackendFeatureDefaults(ownerType: string): { bucket: string, sections: BackendFeatureFlags, defaults: any } {
    const result = {
        bucket: BACKEND_FEATURE_FLAGS,
        sections: {},
        defaults: {},
    };
    Object.keys(BackendFeatureDefaults).forEach(name => {
        if (!BackendFeatureDefaults[name]._only || BackendFeatureDefaults[name]._only.includes(ownerType)) {
            const section = new BackendFeatureSection(name);
            Object.keys(BackendFeatureDefaults[name] || {}).forEach(feature => {
                if (feature !== '_only') {
                    const f = BackendFeatureDefaults[name][feature];
                    if (f.enabled !== undefined) {
                        section.addEnabledToggle(`${feature}-enabled`, f.enabled);
                    }
                }
            });
            result.sections[name] = section;
            result.defaults = {
                ...result.defaults,
                ...section.defaults,
            };
        }
    });

    result.sections['geocoding']?.addNumber('radiusRoads', 10, 1000, 100);

    return result;
}
