import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { hmrBootstrap } from './hmr';
import { scrollbarsIsVisible } from 'app/shared/utils/detect-scrollbars';
import { Router } from '@angular/router';
import { AppService } from 'app/app.service';
import { initialiseMetrics } from 'metrics';

if (environment.production) {
    enableProdMode();
}

if (environment.metrics) {
    initialiseMetrics(environment.metricsKey, environment.metricsHost);
}

// add custom scrollbars only when the browser has scrollbars to style
// i.e. not on mobile or mac when the scrollbars only show on scroll events
if (scrollbarsIsVisible()) {
    document.body.classList.add('scrollbars-visible');
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule)
    .then(moduleRef => {

        // When angular initialises it immediate redirects to the hash fragment url
        // scheme which means we have no way to get the url that we were initially
        // loaded with once the app is running. This information is needed for
        // OpenId Connect redirects, so save it and then manually do the initial
        // navigation.
        const app = moduleRef.injector.get(AppService);
        app.entryUrl = window.document.location.href;

        const router = moduleRef.injector.get(Router);
        const zone = moduleRef.injector.get(NgZone);
        zone.run(() => { // see https://github.com/angular/angular/issues/15770
            router.initialNavigation();
        });

    })
    .catch(error => {
        document.write('Something went terribly wrong: <pre>' + error + '</pre>');
        console.error(error);
        return error;
    });

if (environment.hmr) {
    if (module['hot']) {
        hmrBootstrap(module, bootstrap);
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
} else {
    bootstrap();
}
