import { Component, Input, ChangeDetectionStrategy, ViewChild, EventEmitter, Output, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';
import { EventFeedItem } from '../../feed.model';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'app/shared/components/base/base.component';
import { EventFeedCommentModalComponent } from './comment/comment.component';
import { EventFeedService } from 'app/shared/components/feed/event-feed/event-feed.service';
import { MediaService } from 'app/services/media/media.service';
import { ModalService } from 'app/shared/components/modal';
import { FormBuilderField, customFieldToFormBuilderField } from 'app/shared/components/form-builder';
import { AppService } from 'app/app.service';
import { MeasurementUnitsService } from 'app/services';
import { ExternalEventLink } from './iframe/iframe.component';

@Component({
    selector: 'key-event-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventDetailsComponent extends BaseComponent implements OnChanges {

    tabIndex = 0;
    tabs: string[];

    linkTabs: ExternalEventLink[];

    @Input() showActions = true;
    @Input() isMobile: boolean;
    @Input() event: EventFeedItem;
    @Input() eventFeedService: EventFeedService;
    @Output() onClose = new EventEmitter();

    @ViewChild(EventFeedCommentModalComponent, { static: true }) commentModal: EventFeedCommentModalComponent;

    constructor(
        private app: AppService,
        public ref: ChangeDetectorRef,
        private media: MediaService,
        private modal: ModalService,
        public i18n: TranslateService,
        public measurement: MeasurementUnitsService) {
        super();

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.event) {

            // this.event.details.links = [
            //     { title: 'Street View', url: 'https://www.google.com/maps/embed?api=1&map_action=pano&viewpoint=48.857832,2.295226&heading=-45', type: 'tab' },
            //     { title: 'Crash PDF', url: 'https://www.awesomefilm.com/script/Crash.pdf', type: 'download' },
            //     { title: 'Crash Report', url: 'https://www.awesomefilm.com/script/Crash.pdf', type: 'external' },
            //     { title: 'Admin', url: '#/admin', type: 'internal' },
            //     { title: 'Crash Analysis', url: 'https://www.keytelematics.com/', type: 'modal' },
            // ];

            this.linkTabs = (this.event?.details?.links || []).filter(x => x.type === 'tab');

            this.tabs = [
                this.i18n.instant('ALERTS.TABS.DETAILS'),
                this.i18n.instant('ALERTS.TABS.CONTACTS'),
                ...this.linkTabs.map(x => x.title),
            ];

        }
    }

    async showAcknowledgeModal(item: EventFeedItem): Promise<EventFeedItem> {

        const result = await this.modal.form<any>({
            groups: [{
                name: this.i18n.instant('ALERTS.BUTTONS.ACKNOWLEDGE'),
                fields: this.buildCommentForm(),
            }],
        }, {});

        if (result) {
            return this.eventFeedService.acknowledge(item.id, result).then(event => {
                this.event = event;
                this.ref.markForCheck();
                return event;
            });
        }
        return null;

    }

    async showCommentModal(item: EventFeedItem): Promise<EventFeedItem> {

        const result = await this.modal.form<any>({
            groups: [{
                name: this.i18n.instant('ALERTS.BUTTONS.COMMENT'),
                fields: this.buildCommentForm(),
            }],
        }, {});

        if (result) {
            const dateStart = item.date;
            return this.eventFeedService.comment(item.id, result, dateStart ).then(event => {
                this.event = event;
                this.ref.markForCheck();
                return event;
            });
        }
        return null;

    }

     buildCommentForm(): FormBuilderField[] {
        const customFields = this.app.client.customFields?.event || [];
        return [
            ...customFields.map(field => customFieldToFormBuilderField(field, this.measurement)),
            // only include the default comment field if the user has not overriden it in their own custom fields
            customFields.find(x => x.id === 'comment') ? null : { id: 'comment', title: this.i18n.instant('ALERTS.BUTTONS.COMMENT'), type: 'memo', required: true },
        ].filter(x => x);
    }

    async showRequestVideoModal(item: EventFeedItem): Promise<EventFeedItem> {

        const assetId = this.event.origin ? this.event.origin.id : this.event.details.asset && this.event.details.asset.id;
        const result = await this.media.requestVideoModalForEvent({ assetId: assetId, date: null });
        if (result) {
            try {
                const event = await this.media.requestVideoForEvent(item.owner.id, item.id, result.duration, result.offset, result.cameras);
                this.event = { ...this.event, media: event.media };
                this.ref.markForCheck();
            } catch (err) {
                this.modal.error(err.message);
            }
            
        }
        return this.event;

    }

    close() {
        this.onClose.emit();
    }

}
