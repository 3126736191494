import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, signal, SimpleChanges, WritableSignal } from '@angular/core';
import { LabelListItem } from '@key-telematics/fleet-api-client';
import { TranslateModule } from '@ngx-translate/core';
import { IKuiDropdownMenuItem, KuiDropdownModule, KuiIconModule } from 'app/key-ui';
import { ICONS } from '../../admin/entities/icons';
import { KuiLoaderModule } from 'app/key-ui/loader/loader.module';

@Component({
  selector: 'key-label-dropdown-list',
  standalone: true,
  imports: [NgIf, TranslateModule, KuiIconModule, KuiDropdownModule, KuiLoaderModule],
  templateUrl: './label-dropdown-list.component.html',
  styleUrl: './label-dropdown-list.component.scss'
})
export class LabelDropdownListComponent implements OnChanges {
  @Input() labelOptions: LabelListItem[];
  @Input() showLabelListSpinner: boolean;
  
  @Output() onAction = new EventEmitter();

  labelMenu: WritableSignal<IKuiDropdownMenuItem[]> = signal([]);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.labelOptions) {
      this.createLabelDropdownMenu()
    }
  }

  createLabelDropdownMenu() {
      const optionMenuTree: IKuiDropdownMenuItem[] = [];

        for (const item of this.labelOptions) {
          const dropdownHasChildren = item.values.length > 0;

          const option = {
            id: item.label,
            text: item.name,
            type: item.values.length > 0 ? 'expandable' : 'action',
            isExpanded: false,
            icon: dropdownHasChildren ? ICONS.arrowright : ICONS.label,
            closeDropdownOnClicked: dropdownHasChildren ? false : true,
            children: dropdownHasChildren ? [] : null,
            action: () => { 
              item.values.length > 0 ? null : this.onAction.emit(item.label);
            }
          }
  
          if (item.values.length > 0) {
            for (const sub of item.values) {
              const subOption = {
                id: sub.label,
                text: sub.name,
                type: 'action',
                indent: 1.5,
                icon: ICONS.label,
                closeDropdownOnClicked: true,
                action: () => {
                  const labelId = `${item.label}/${sub.label}`;
                  this.onAction.emit(labelId);
                }
              }
              option.children.push(subOption);
            }
          }
          optionMenuTree.push(option);
      }
    this.labelMenu.set(optionMenuTree);
  }

}
