import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { SelectionService } from './selection.service';
import { SelectionItem, EntityResult, EntityListOptions } from '../selection.model';
import { ASSET_ICON_INDEX } from 'app/shared/model';
import { MAP_COLORS, MAP_COLORS_ENUM } from 'app/shared/components/map/map.markers';
import { AssetResponse, AssetListItem } from '@key-telematics/fleet-api-client';
import { ClientService } from 'app/services';
import { escapeRqlValue } from 'app/shared/utils/rql';

@Injectable()
export class AssetSelectionService extends SelectionService<SelectionItem>  {

    constructor(public app: AppService, public clientService: ClientService) {
        super(app);
    }

    private items: SelectionItem[] = [];

    getEntityList(id: string, { offset, limit, sort, filter }: EntityListOptions): Promise<EntityResult<SelectionItem>> {
        return this.app.api.entities.listAssets(id, offset, limit, sort, filter)
            .then(res => ({
                items: res.items.map(x => this.mapAsset(x)),
                total: res.count,
            })).then(result => {
                this.items = result.items;
                return result;
            });
    }

    getEntity(id: string): Promise<SelectionItem> {
        const result = this.items.find(x => x.id === id);
        if (result) {
            return Promise.resolve(result);
        } else {
            return this.app.api.entities.getAsset(id).then(asset => {
                if (asset.owner.id !== this.app.client.id && !asset.sharedWith.find(x => x.id === this.app.client.id)) {
                    return this.clientService.loadClient(asset.owner.id).then(() => asset);
                }
                return asset;
            }).then(asset => {
                return this.mapAsset(asset);
            });
        }    
    }

    private mapAsset(asset: AssetResponse | AssetListItem): SelectionItem {
        return {
            ownerId: asset.owner.id,
            id: asset.id,
            name: asset.name,
            type: asset.assetType && asset.assetType.name,
            assetTypeId: asset.assetType && asset.assetType.id,
            iconName: ASSET_ICON_INDEX[asset.assetType && asset.assetType.name.toLowerCase()] || ASSET_ICON_INDEX['default'],
            color: ((typeof asset.color === 'number')
                ? MAP_COLORS[asset.color || 0]
                : MAP_COLORS[MAP_COLORS_ENUM[asset.color] || 0]),
            fields: asset.fields,
        };
    }

    getDefaultEntityOptions(): EntityListOptions {
        return {
            offset: 0,
            limit: this.app.features.page.overview.pageSize || 300,
            sort: 'name:asc',
            filter: 'state=active',
        };
    }

    getFilterRqlString(value?: string): string {
        return value ? `state=active,name=*${escapeRqlValue(value)}*` : 'state=active';

    }
}
