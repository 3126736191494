import { Injectable, signal, WritableSignal } from '@angular/core';
import { FeedOriginType } from '../feed.service';
import { AuthService, MeasurementUnitsService } from 'app/services';
import { AppService } from 'app/app.service';
import { EventFeedItem, FeedResult, FeedHistoryResult } from 'app/shared/components/feed/feed.model';
import { EventFeedService } from '../event-feed/event-feed.service';
import { Observable } from 'rxjs';
import { FeedsGetRecentAlertFeedGQL } from 'app/shared/graphql';
import { map } from 'rxjs/operators';
import { MediaService } from 'app/services/media/media.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../modal';

@Injectable()
export class AlertFeedService extends EventFeedService {
    showAcknowledged: WritableSignal<boolean> = signal(true);

    constructor(app: AppService, auth: AuthService, units: MeasurementUnitsService, media: MediaService, i18n: TranslateService, modal: ModalService) {
        super(app, auth, units, media, i18n, modal);
    }

    getFeedSubscription(clientId: string, originType: FeedOriginType, originId: string, limit: number): Observable<FeedResult<EventFeedItem>> {
        return new FeedsGetRecentAlertFeedGQL(this.app.api.gql).subscribe({
            client: clientId,
            asset: originType === 'asset' ? originId : undefined,
            alert: originType === 'alert' ? originId : undefined,
            limit: limit,
        }).pipe(map(result => {
            const items = result.data.getRecentAlertFeed;
            return {
                count: items.length,
                sequence: [
                    !!items.length ? Math.min(...items.map(item => this.dateToSequence(item.modifiedDate))) : 0,
                    !!items.length ? Math.max(...items.map(item => this.dateToSequence(item.modifiedDate))) : 0,
                ],
                items: items.map(item => this.mapEventResponse(item as any)),
            };
        }));
    }

    getFeed(clientId: string, originType: FeedOriginType, originId: string, sequence: number, direction: 'forward' | 'backward', limit: number): Promise<FeedResult<EventFeedItem>> {
        return this.app.api.data.getAlertFeed(clientId, sequence, direction, limit, originType === 'asset' ? originId : null, originType === 'alert' ? originId : undefined)
            .then(result => ({
                count: result.count,
                sequence: [
                    result.sequence,
                    result.count !== 0 ? Math.max(...result.items.map(item => this.dateToSequence(item.modifiedDate))) : 0,
                ],
                items: result.items.map(item => this.mapEventResponse(item)),
            }));
    }

    protected getFeedHistory(_clientId: string, _originType: FeedOriginType, originId: string, start: string, end: string, limit: number): Promise<FeedHistoryResult<EventFeedItem>> {
        return this.app.api.data.getAlertHistory(originId, start, end, limit)
            .then(result => ({
                count: result.count,
                start: result.start,
                end: result.end,
                limit: result.limit,
                items: result.items.map(item => this.mapEventResponse(item)),
            }));
    }

}
