<div class="content-header"
    [class.content-header__sub]="sub">
    <ng-container *ngIf="custom; else main">
        <ng-content></ng-content>
    </ng-container>
    <ng-template #main>
        <ng-container *ngIf="icon">
            <div class="mobile-nav"
                [ngSwitch]="icon">
                <kui-icon kui-action
                    name="bars"
                    *ngSwitchCase="'menu'"
                    (click)="action.emit('menu')"></kui-icon>
                <kui-icon kui-action
                    name="chevron-left"
                    *ngSwitchCase="'back'"
                    (click)="action.emit('back')"></kui-icon>
                <kui-icon kui-action
                    name="times"
                    *ngSwitchCase="'close'"
                    (click)="action.emit('close')"></kui-icon>
            </div>
        </ng-container>

        <ng-content select="[kui-content-header-custom-content]"></ng-content>

        <ng-content select="[kui-content-header-title]"></ng-content>

        <div class="tab-mount">
            <ng-content select="kui-tab-bar"></ng-content>
        </div>

        <!-- TITLE -->
        <div *ngIf="title"
            kui-content-header-title
            [class.d-flex]="isDesktop">
            <div (click)="_title.click && _title.click()" [class.pointer]="_title.click">
                <kui-icon *ngIf="_title?.iconName && _title?.iconPosition === 'prefix'"
                    class="mr-1"
                    size="sm"
                    [name]="_title?.iconName"></kui-icon>
                {{ _title?.value }}
                <kui-icon *ngIf="_title?.iconName && _title?.iconPosition === 'sufix'"
                    class="ml-1"
                    [name]="_title?.iconName"></kui-icon>
            </div>
        </div>

        <!-- DESKTOP TABS -->
        <div class="tab-mount"
            *ngIf="isDesktop">
            <kui-tab-bar *ngIf="!!tabs?.length"
                [items]="tabs"
                [closeButton]="closeableTabs"
                [selected]="activeTab?.id"
                [draggable]="draggableTabs"
                (reorder)="dragTab($event)"
                (selection)="selectTab($event)"
                (close)="closeTab($event)">
            </kui-tab-bar>
        </div>

        <div class="icon-mount">
            <kui-content-header-actions *ngIf="!!actions?.length">

                <!-- ACTIONS -->
                <ng-container *ngFor="let action of actions">
                    <ng-container *ngIf="action.menu || action.template; else icon;">
                        <kui-dropdown class="pl-1"
                            [style]="'none'"
                            [menu]="action.menu"
                            [customContent]="action.template"
                            [customContentContext]="action.templateContext">

                            <kui-icon kui-action
                                [name]="action.icon"
                                [class.active]="action.active"
                                [title]="action.name"></kui-icon>

                        </kui-dropdown>
                    </ng-container>

                    <ng-template #icon>
                        <kui-icon kui-action
                            class="pl-1"
                            [class.active]="action.active"
                            [name]="action.icon"
                            [title]="action.name"
                            (click)="action.click()">
                        </kui-icon>
                        <span *ngIf="['text', 'both'].includes(action.show)"
                            kui-action
                            (click)="action.click()">{{ action.name }}</span>
                    </ng-template>

                </ng-container>
            </kui-content-header-actions>

            <ng-content select="kui-content-header-actions"></ng-content>
        </div>
    </ng-template>
</div>

<!-- MOBILE TABS - NOTE: only show when isDesktop was explicitly set to false -->
<kui-tab-bar *ngIf="!!tabs?.length && isDesktop === false"
    [items]="tabs"
    [closeButton]="closeableTabs"
    [selected]="activeTab?.id"
    [draggable]="draggableTabs"
    (reorder)="dragTab($event)"
    (selection)="selectTab($event)"
    (close)="closeTab($event)"
    class="mobile-tabs">
</kui-tab-bar>