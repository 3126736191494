import { Component, computed, effect, HostBinding, input, Signal } from '@angular/core';

@Component({
    selector: 'key-layout-grid-columns',
    templateUrl: './layout-grid-columns.component.html',
})
export class LayoutGridColumnsComponent { 
    direction: Signal<'horizontal' | 'vertical'> = input('horizontal');
    reverse: Signal<boolean> = input(false);

    directionClasses = computed(() => this.getDirectionClasses(this.direction(), this.reverse()));

    @HostBinding('class') class = '';

    constructor() {
        effect(() => {
            this.class = this.directionClasses().join(' ');
        });
    }

    private getDirectionClasses(direction: 'horizontal' | 'vertical', reverse: boolean): string[] {
        const sharedClasses = ['d-flex', 'flex-stretch', 'h-100'];

        if (direction === 'horizontal') {
            return [...sharedClasses, reverse ? 'flex-row-reverse' : 'flex-row'];
        }

        if (direction === 'vertical') {
            return [...sharedClasses, reverse ? 'flex-column-reverse' : 'flex-column'];
        }
    }
}
