import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { KuiIconModule } from 'app/key-ui';

export interface LabelBadge {
    label: string,
    name: string,
    color?: string
}

export enum BadgeClickActionType {
    DELETE
}

@Component({
    selector: 'key-label-badges',
    standalone: true,
    imports: [NgIf, NgFor, KuiIconModule, NgClass],
    templateUrl: './label-badges.component.html'
})

export class LabelBadgesComponent {
    BadgeClickActionType = BadgeClickActionType;
    labels = input.required<LabelBadge[]>();
    clickable = input<boolean>();
    actionType = input<BadgeClickActionType>();
    
    onAction = output<string>();
}
