import { ElementRef, input, Input, output, Renderer2, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef, Component, OnChanges } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { FilterPanelConfig } from './filter-panel.model';


@Component({
    selector: 'key-filter-panel',
    styleUrls: ['filter-panel.component.scss'],
    templateUrl: 'filter-panel.component.html',
})
export class KeyFilterPanelComponent extends BaseComponent implements OnChanges {

    activeCards: { [key: string]: boolean } = {};
    @Input() width = '300px';
    @Input() filters: FilterPanelConfig[];
    isMobile = input<boolean>(false);
    
    closePanel = output<boolean>()
    
    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private ref: ChangeDetectorRef
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.width) {
            this.renderer.setStyle(this.el.nativeElement, 'width', this.width);
            this.renderer.setStyle(this.el.nativeElement, 'flex-basis', this.width);
        }
    }

    cardActive(name: string): boolean {
        let value = this.activeCards[name];
        if (value === undefined) {
            value = true; // this makes it active by default
        }
        return value;
    }


    onCardClick(name: string) {
        this.activeCards[name] = !this.cardActive(name);
        this.ref.markForCheck();
    }

    onClosePanel() {
        this.closePanel.emit(true);
    }

}
