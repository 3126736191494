import { Observable, Subject, Subscription } from 'rxjs';
import { FeedItem, FeedResult, FeedHistoryResult } from 'app/shared/components/feed/feed.model';
import { AuthService } from 'app/services/auth/auth.service';
import { Injectable, OnDestroy } from '@angular/core';
import { AppService } from 'app/app.service';
import * as moment from 'moment-timezone';

export type FeedOriginType = 'client' | 'asset' | 'alert' | (string & {});

export interface UpdatePayload<T> {
    historic?: boolean;
    added: number;
    sequence?: number[];
    items: T[];
}

@Injectable()
export abstract class FeedService<T extends FeedItem> implements OnDestroy {
    private POLLING_INTERVAL = 10000;

    supportsWebsockets = true;

    hasPolling: boolean;
    dateRange: { start: string, end: string };
    lastSequence = null;
    nextSequence = null;
    settings: any = {};

    originId: string;

    private subscription: Subscription;

    updatesSubject: Subject<UpdatePayload<T>> = new Subject();
    get updates$(): Observable<UpdatePayload<T>> {
        return this.updatesSubject.asObservable();
    }

    lastError: Error = null; // keep track of the last error to prevent duplicate events from being fired
    private onErrorSubject: Subject<Error> = new Subject();
    get onError$(): Observable<Error> {
        return this.onErrorSubject.asObservable();
    }

    items: { [key: string]: T };

    constructor(public app: AppService, public auth: AuthService) { }

    // returns the list of items and starts polling for updates in the background
    initializeFeed(clientId: string, originType: FeedOriginType, originId: string, poll: boolean = true, limit: number = 10): Promise<FeedItem[]> {
        if (this.supportsWebsockets && this.app.api.websocketConnected && poll) {
            return this.initializeSubscriptionFeed(clientId, originType, originId, limit);
        } else {
            return this.initializePollingFeed(clientId, originType, originId, poll, limit);
        }
    }

    // returns the list of items and starts polling for updates in the background
    initializeSubscriptionFeed(clientId: string, originType: FeedOriginType, originId: string, limit: number = 10): Promise<FeedItem[]> {

        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }

        this.originId = originId;
        this.items = {};

        return new Promise<FeedItem[]>((resolve, reject) => {
            let cnt = 0;
            this.subscription = this.getFeedSubscription(clientId, originType, originId, limit).subscribe(
                result => {
                    this.setError(null);

                    if (this.lastSequence === null) {
                        this.lastSequence = result.sequence[0];
                    }

                    this.nextSequence = result.sequence[1] || 0;

                    if (cnt === 0) { cnt++; resolve(result.items); }
                    if (this.originId !== originId) { return; } // stop the polling if we're not on the current id
                    this.addItems(this.lastSequence, result.items);

                },
                error => {
                    if (cnt === 0) { cnt++; reject(error); }
                    this.setError(error);
                }
            );
        });
    }


    // returns the list of items and starts polling for updates in the background
    initializePollingFeed(clientId: string, originType: FeedOriginType, originId: string, poll: boolean = true, limit: number = 10): Promise<FeedItem[]> {
        this.originId = originId;
        this.items = {};
        this.hasPolling = true;
        return this.getOlderFeedItems(clientId, originType, originId, limit)
            .then(items => {
                if (this.originId !== originId) { return; } // stop the polling if we're not on the current id
                const run = (sequence: number, timeout: number = this.POLLING_INTERVAL) => {
                    if (this.originId !== originId) { return; } // stop the polling if we're not on the current id
                    setTimeout(() => {
                        if (this.originId !== originId) { return; } // stop the polling if we're not on the current id
                        return this.getFeed(clientId, originType, originId, sequence, 'forward', limit)
                            .then(res => {
                                this.nextSequence = res.sequence[1] || 0;
                                this.setError(null);
                                this.addItems(sequence, res.items);
                                run(this.nextSequence, this.POLLING_INTERVAL);
                            })
                            .catch(error => {
                                this.setError(error);
                                return this.auth.isAuthenticated().then(authed => { // stop running the timer if we're no longer authenticated
                                    if (authed) {
                                        run(sequence, this.POLLING_INTERVAL);
                                    }
                                });

                            });

                    }, timeout);

                };

                if (poll) {
                    run(this.lastSequence);
                }


                return items;
            });
    }

    addItems(sequence: number, items: T[], historic = false) {
        const added = this.mergeItems(items);
        this.updatesSubject.next({
            historic,
            items: items,
            sequence: [sequence, this.nextSequence],
            added: added,
        });
    }

    private mergeItems(items: T[]): number {
        let added = 0;
        items.forEach(item => {
            if (!this.items[item.id]) {
                added++;
            }
            this.items[item.id] = item;
        });
        return added;

    }

    protected dateToSequence(utcDateString: string): number {
        return moment.utc(utcDateString).unix();
    }


    clearFeedItems() {
        this.items = {};
    }

    stopPolling() {

        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }

        if (this.hasPolling) {
            this.clearFeedItems();
        }
        this.lastSequence = null;
        this.originId = null;
    }

    ngOnDestroy() {
        this.stopPolling();
    }

    getOlderFeedItems(clientId: string, originType: FeedOriginType, originId: string, limit: number = 10): Promise<T[]> {
        return this.getFeed(clientId, originType, originId, this.lastSequence || 0, 'backward', limit)
            .then(result => {
                this.lastSequence = result.sequence[0];
                const added = this.mergeItems(result.items);
                this.updatesSubject.next({
                    historic: true,
                    added: added,
                    sequence: [0, result.sequence[1]],
                    items: result.items,
                });
                return result.items;
            });
    }

    setError(err: Error) {
        if (err !== this.lastError) {
            this.lastError = err;
            this.onErrorSubject.next(err);
        }
    }

    setDateRange(start: string, end: string) {
        this.dateRange = { start, end };
    }

    updateSettings(settings: any) {
        this.settings = settings;
    }

    clearDateRange() {
        this.dateRange = null;
    }

    getItem(id: string): Promise<T> {
        // TODO: this needs to go get the item directly from the api, but the stateservice doesn't support this yet,
        // so what follows is a very dirty hack (which would have been cleaner, but what's the point).
        return new Promise((resolve, reject) => {
            let cnt = 0;
            const check = () => {
                cnt++;
                if (this.items && this.items[id]) {
                    resolve(this.items[id]);
                } else {
                    if (cnt < 12) {
                        setTimeout(() => check(), 500);
                    } else {
                        reject(new Error('Event not found'));
                    }
                }
            };
            check();
        });
    }

    getHistory(clientId: string, originType: FeedOriginType, originId: string, start: string, end: string, limit: number): Promise<FeedHistoryResult<T>> {
        return this.getFeedHistory(clientId, originType, originId, start, end, limit).then(result => {
            this.items = {};
            const added = this.mergeItems(result.items);
            this.updatesSubject.next({
                historic: true,
                added: added,
                sequence: undefined,
                items: result.items,
            });
            return result;
        }) as Promise<FeedHistoryResult<T>>; // .catch is not available on PromiseLike

    }

    // wrappers for the api calls to a specific feed
    // and returns an array of the correct interface for that feed

    protected abstract getFeedSubscription(clientId: string, originType: FeedOriginType, originId: string, limit: number): Observable<FeedResult<T>>;

    protected abstract getFeed(clientId: string, originType: FeedOriginType, originId: string, sequence: number, direction: 'forward' | 'backward', limit: number): Promise<FeedResult<T>>;

    protected abstract getFeedHistory(clientId: string, originType: FeedOriginType, originId: string, start: string, end: string, limit: number): PromiseLike<FeedHistoryResult<T>>;
}
