import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, HostListener, Injector, Input, OnChanges, Output, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ErrorLoggerService, NotificationService } from 'app/services';
import { BaseComponent } from 'app/shared/components/base/base.component';
import { AssetSelectionService } from './services/asset-selection.service';
import { EntityListOptions, SelectionItem } from './selection.model';
import { SelectionService } from './services/selection.service';
import { ERRORS } from 'app/shared/model/errors.model';
import { ClientSelectionService } from './services/client-selection.service';
import { debounce } from 'lodash';
import { AccountSelectionService } from './services/account-selection.service';

@Component({
    selector: 'key-selection',
    templateUrl: './selection.component.html',
    styleUrls: ['./selection.component.scss'],
    providers: [AssetSelectionService, ClientSelectionService, AccountSelectionService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionComponent extends BaseComponent implements OnChanges, AfterViewInit {
    errorMessage: string;
    searchValue: string;
    entityService: SelectionService<SelectionItem>;
    loaded: boolean;

    entities: SelectionItem[];
    typeName: string;

    highlightIndex = 0;
    page = 1;
    limit = 0;
    total = 0;

    debouncedUpdateSearchValue = debounce(this.changeSearch, 500, { trailing: true, leading: false });

    @ViewChild('assetList') assetList: ElementRef;
    @ViewChild('searchBar', { static: true }) searchBar: ElementRef;

    @HostBinding('class.d-flex') flexClass = true;
    @HostBinding('class.flex-column') columnClass = true;
    @HostBinding('class.flex-stretch') stretchClass = true;

    @Input() type: 'client' | 'asset' | 'account';
    @Input() id: string;
    /* any extra filter to be applied to the entity list */
    @Input() filter?: string; 

    @Output() onSelection: EventEmitter<SelectionItem> = new EventEmitter();

    @HostListener('document:keydown', ['$event']) keyboardInputs(event: KeyboardEvent) {
        if (!this.entities || !this.entities.length) { return; }

        switch (event.key) {
            case 'ArrowUp':
                this.highlightIndex--;
                if (this.highlightIndex < 0) {
                    this.highlightIndex = 0;
                }
                break;
            case 'ArrowDown':
                this.highlightIndex++;
                if (this.highlightIndex >= this.entities.length) {
                    this.highlightIndex = this.entities.length - 1;
                }
                break;
            case 'Enter':
                const entity = this.entities[this.highlightIndex];
                if (entity) {
                    this.selectEntity(entity);
                }
                break;
            default:
                break;
        }
    }

    @HostListener('keyup', ['$event']) keyUp(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.changeSearch(null);
        }

        if (event.key === 'Enter') {
            this.changeSearch(this.searchValue);
        }
    }


    constructor(
        private injector: Injector,
        private error: ErrorLoggerService,
        private changes: ChangeDetectorRef,
        public i18n: TranslateService,
        private notify: NotificationService
    ) {
        super();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            try {
                this.searchBar.nativeElement.focus();
            } catch { }
        }, 100);
    }


    ngOnChanges(changes) {
        const id = changes.id && changes.id.currentValue;

        if (id) {
            if (!this.entityService) {
                this.injectEntityService();
            }

            this.changeSearch(''); // make sure our filters are updated in one place
        }

        if (this.type === 'asset') {
            const type = 'assets_and_vehicles';
            this.typeName = this.i18n.instant(`ADMIN.ENTITIES.${type.toUpperCase()}`).toLowerCase();
        } else {
            this.typeName = this.i18n.instant(`ADMIN.ENTITIES.${this.type.toUpperCase()}S`).toLowerCase();
        }

    }

    injectEntityService() {
        // inject the correct entity service based on the type input
        switch (this.type) {
            case 'client':
                this.entityService = this.injector.get(ClientSelectionService);
                break;
            case 'asset':
                this.entityService = this.injector.get(AssetSelectionService);
                break;                
            case 'account':
                this.entityService = this.injector.get(AccountSelectionService);
                break;
            default:
                console.warn('No/unsupported type specified on SelectionComponent. To make sure the correct entity service is used please add the correct type to your <key-selection type="ENTITY_TYPE_HERE" /> tag.');
                break;
        }
        this.limit = this.entityService.options.limit;
    }

    setLoaded(state: boolean) {
        this.loaded = state;
    }

    showError(error: any) {
        this.error.trackException(error, [ERRORS.FORBIDDEN_ERROR]);
        this.errorMessage = error && this.notify.translateError(error);
        this.changes.markForCheck();
    }



    changeSearch(filter: string) {
        this.page = 1;
        if (filter !== undefined) {
            this.searchValue = filter || '';
            let value = this.entityService.getFilterRqlString(this.searchValue.toLowerCase());
            if (this.filter) {
                // append any extra filters we may have
                value = [value, this.filter].filter(x => x).join(',');
            }
            this.entityService.updateEntityOption('filter', value);
            this.updateEntityList(this.id, this.entityService.options);
        }
    }

    selectEntity(entity: SelectionItem) {
        this.onSelection.emit(entity);
    }

    getFirstLetter(name: string): string {
        const str = name.replace(/[\W|\d]+/, '');
        return str[0] ? str[0].toUpperCase() : '';
    }

    updateEntityList(id: string, options: EntityListOptions) {
        this.setLoaded(false);
        this.showError(null);
        this.highlightIndex = -1;

        options.offset = options.limit * (this.page - 1);

        this.entityService.getEntityList(id, options)
            .then(res => {
                this.setLoaded(true);
                this.total = res.total;
                this.entities = res.items;
                this.changes.markForCheck();
            })
            .catch(error => {
                this.setLoaded(true);
                this.showError(error);
            });
    }

    onPageChanged(page: number) {
        this.page = page;
        this.updateEntityList(this.id, this.entityService.options);
        // user has changed the page, scroll to the top of the asset list
        this.assetList?.nativeElement.scrollTo(0,0);
    }
}
