import { Component, EventEmitter, OnInit, Output, computed, input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentHeaderAction } from 'app/key-ui/content-header/content-header.component';
import { DateRangeChangeEvent, TimePeriod } from 'app/key-ui/daterange';
import { DateRange } from 'app/shared/utils/dates';
import * as moment from 'moment-timezone';

// IDs derived from the timePeriods array
export type ValidTimePeriod = "today" | "yesterday" | "last7Days" | "last30days" | "thisMonth";
export interface DateHeaderSettings {
    actions: ContentHeaderAction[];
}
@Component({
    selector: 'key-date-range-select',
    templateUrl: './date-range-selection.component.html',
})
export class KeyDateRangeSelectComponent implements OnInit {

    headerActions = input<DateHeaderSettings>();
    currentString = input<string>();
    maxDayRange = input<number>(7);
    minDate = input<string>();
    maxDate = input<string>();
    timePeriod = input<ValidTimePeriod>();

    @Output() onDateSelection = new EventEmitter<DateRange>();

    chosenTimePeriod = computed(() => {
        return this.timePeriods.find(x => x.id === this.timePeriod());
    })

    readonly timePeriods: TimePeriod[] = [
        {
            id: "today",
            text: this.i18n.instant('REPORTING.ITEMS.FIELDS.VALUES.TODAY'),
            getDateRange: () => {
                return {
                    start: moment().startOf('day').toISOString(),
                    end: moment().endOf('day').toISOString(),
                }
            },
        },
        {
            id: "yesterday",
            text: this.i18n.instant('REPORTING.ITEMS.FIELDS.VALUES.YESTERDAY'),
            getDateRange: () => {
                const yesterday = moment().subtract(1, 'days');
                return {
                    start: yesterday.clone().startOf('day').toISOString(),
                    end: yesterday.clone().endOf('day').toISOString(),
                }
            },
        },
        {
            id: "last7Days",
            text: this.i18n.instant('REPORTING.ITEMS.FIELDS.VALUES.LAST_7_DAYS'),
            getDateRange: () => {
                const today = moment();
                return {
                    start: today.clone().startOf('day').subtract(7, 'days').startOf('day').toISOString(),
                    end: today.clone().startOf('day').endOf('day').toISOString(),
                }
            },
        },
        {
            id: "last30days",
            text: this.i18n.instant('REPORTING.ITEMS.FIELDS.VALUES.LAST_30_DAYS'),
            getDateRange: () => {
                const today = moment();
                return {
                    start: today.clone().startOf('day').subtract(30, 'days').startOf('day').toISOString(),
                    end: today.clone().startOf('day').endOf('day').toISOString(),
                }
            },
        },
        {
            id: "thisMonth",
            text: this.i18n.instant('REPORTING.ITEMS.FIELDS.VALUES.THIS_MONTH'),
            getDateRange: () => {
                const today = moment();
                return {
                    start: today.clone().startOf('month').toISOString(),
                    end: today.clone().endOf('day').toISOString(),
                }
            },
        },
    ]

    constructor(
        private i18n: TranslateService
    ) {
    }

    ngOnInit(): void {}

    handleChange(event: DateRangeChangeEvent) {
        this.onDateSelection.emit({
            start: event.start,
            end: event.end
        });
    }

}
