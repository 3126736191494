import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KuiModule } from 'app/key-ui';
import { TranslateModule } from '@ngx-translate/core';
import { LabelBadgesComponent } from './label-badges.component';
import { LabelDropdownListComponent } from './label-dropdown-list/label-dropdown-list.component';

@NgModule({
    imports: [
        CommonModule,
        KuiModule,
        TranslateModule,
        LabelDropdownListComponent,
        LabelBadgesComponent
    ],
    exports: [
        LabelDropdownListComponent,
        LabelBadgesComponent
    ]
})
export class KeyLabelsModule { }
