import { Component, ChangeDetectionStrategy, ViewChild, Inject } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { map, filter, takeUntil, debounceTime, take } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Howl } from 'howler';
import * as moment from 'moment-timezone';
import { GoogleAnalyticsService, NavigationService, MatchMediaService, ClientService } from 'app/services';
import { AppService } from 'app/app.service';
import { CurrentUser } from 'app/shared/model';
import { MobileNavigationActionsService, AuthService, NotificationService, NotificationDetails } from 'app/services';
import { BaseComponent } from 'app/shared/components';
import { KuiModalComponent } from 'app/key-ui/modal/modal.component';
import { ErrorLoggerService } from './services/error-logger/error-logger.service';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { PAGE_ICONS } from './app.features';
import { ThemeResponse } from '@key-telematics/fleet-api-client';
import { ImageService } from './services/images/image.service';
import { NavigationGroup, NavigationItem } from './services/navigation/navigation.service';
import { TopNavService } from './key-ui/top-nav';
import { get } from 'lodash';
import { KeyTranslateLoader } from './services/translate/translate-loader';
import { UserNotificationService } from './services/user-notification/user-notification.service';
import { KuiSnackbarService } from './key-ui/snackbar/snackbar.service';
import { parseUserNotification } from './shared/components/feed/notification-feed/notification-feed.utils';
import { DeviceDetectorService, BROWSERS } from 'ngx-device-detector';
import { KuiMenuComponent } from './key-ui/menu/menu.component';
import { KuiNavComponent } from './key-ui/nav/nav.component';
import { SelectionModalComponent } from './shared/components/selection/modal/selection-modal.component';
import { ModalService } from './shared/components/modal';
import { MetricsService } from './services/metrics/metrics.service';

@Component({
    selector: 'key-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    // never change the root to OnPush, this forces OnPush for everything downstream
    changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent extends BaseComponent {
    errorMessage: string;
    noticeMessage: string;
    notification: NotificationDetails;
    showIE11Message: boolean;

    user$: Observable<CurrentUser>;
    user: CurrentUser;
    userHasMultipleAccounts = false;
    userAvatar: string;
    sidebarToggle$: Observable<boolean>;
    sidebarCollapsed: boolean;
    clientLogo: string;
    clientName: string;

    sidebarPosition = 'left';

    showSidebar: boolean;
    icons = PAGE_ICONS;
    groups: NavigationGroup[];
    isMobile: boolean;

    currentPageTitle: string;

    notificationSound: Howl;
    unseedNotifications = 0;

    navContainer: HTMLDivElement;
    @ViewChild('menu') set kuiNavMenu(nav: KuiMenuComponent) {
        if (nav) {
            const el = nav.el.nativeElement.querySelector('.app-menu-content-inner');
            this.navContainer = el;
        }
    }
    @ViewChild('actionsNav') actionsNav: KuiNavComponent;

    @ViewChild('notifyModal', { static: true }) notifyModal: KuiModalComponent;
    @ViewChild('errorModal', { static: true }) errorModal: KuiModalComponent;
    errorModalInfo: {
        name: string;
        message: string;
    };

    constructor(
        public app: AppService,
        private auth: AuthService,
        private images: ImageService,
        public router: Router,
        private error: ErrorLoggerService,
        public i18n: TranslateService,
        public mobileActions: MobileNavigationActionsService,
        private googleAnalytics: GoogleAnalyticsService,
        private notify: NotificationService,
        private navigation: NavigationService,
        private topNav: TopNavService,
        private titleService: Title,
        public userNotifications: UserNotificationService,
        private snackbar: KuiSnackbarService,
        private matchMedia: MatchMediaService,
        @Inject(DOCUMENT) private document: HTMLDocument,
        private deviceService: DeviceDetectorService,
        private modal: ModalService,
        private clientService: ClientService,
        private metricsService: MetricsService

    ) {
        super();

        this.error.unhandledError$.subscribe(err => this.showErrorModal(err));
        this.notify.onNotify.subscribe(item => this.showNotification(item));


        try {
            this.notificationSound = new Howl({
                src: ['assets/audio/notify.mp3'],
            });
        } catch (err) {
            console.warn('Unable to initialize sound system', err);
            this.notificationSound = null;
        }

        this.app.initialiseState();

        this.app.version$.pipe(
            takeUntil(this.destroyed)
        ).subscribe(
            () => this.noticeMessage = this.i18n.instant('LOGIN.OUTDATED'),
            err => console.error('environment polling error', err) // no need to do more than logging the error
        );

        // start listening to router events and log page views to google analytics
        this.googleAnalytics.pageViews.subscribe();

        this.user$ = this.app.user$;
        this.sidebarToggle$ = mobileActions.sidebarToggle$;

        this.app.client$.pipe(takeUntil(this.destroyed)).subscribe(async client => {
            if (client) {
                this.titleService.setTitle(client.name);
                if (client.theme && client.theme.id && client.theme.id !== 'inherited') {
                    const theme = await this.app.api.entities.getTheme(client.theme.id).catch(() => null as ThemeResponse);
                    this.app.setTheme({
                        ...theme,
                        logoPath: client.logoPath,
                    });
                } else {
                    // get from domain if the theme is set on 'inherited'
                    this.app.getDomainTheme(this.document.location.hostname);
                }

                // do this on client change as the items could vary with client
                this.groups = this.navigation.getNavigationGroups();

            }

            this.clientName = client ? client.name : '';
            this.clientLogo = await this.images.getForClient(client);
        });

        this.onAll([
            this.app.theme$.pipe(map(theme => theme && get(theme.settings, 'navigation.size', 'auto') !== 'hidden')),
            this.user$.pipe(map(user => !!user)),
            this.router.events.pipe(filter(event => event instanceof NavigationEnd), map((event: NavigationEnd) => event.url.search(/login|client-select|user-select|reset|welcome|otp|password/g) < 0)),
            this.matchMedia.isMobile,
            this.matchMedia.isTablet,
        ], ([theme, user, url, mobile, tablet]) => {
            this.isMobile = mobile || tablet;

            // TODO: sidebar menu is the navigation for any mobile theme for now, but it would be cool to come up with another navigation paradigm if the theme has a top navigation only.
            // Future dude: get to work!!
            this.showSidebar = (this.isMobile || theme) && user && url;
            this.userHasMultipleAccounts = this.auth.users.length > 1;
        });


        this.on(this.app.theme$, theme => {
            this.sidebarPosition = get(theme.settings, 'navigation.position', 'left');
        });

        // this language will be used as a fallback when a translation isn't found in the current language
        i18n.setDefaultLang('en');

        const languageEndpoint = KeyTranslateLoader.getLanguageEndpoint();
        if (languageEndpoint) {
            i18n.use(languageEndpoint.lang);
            moment.locale(languageEndpoint.lang);
        }

        this.app.user$.subscribe(async user => {
            if (user) {

                if (languageEndpoint) {
                    await i18n.use(languageEndpoint.lang).toPromise(); // we need to wait for this or the language could be wrong
                    moment.locale(languageEndpoint.lang);
                } else {
                    let lang = user.language || 'en';
                    if (lang === 'en-us') {
                        lang = 'en';
                    }
                    await i18n.use(lang).toPromise(); // we need to wait for this or the language could be wrong
                    moment.locale(lang);
                }

                this.user = user;
                // do this on user change as the language could have changed
                this.groups = this.navigation.getNavigationGroups();

                try {
                    // get the avatar as a data url to prevent flashes when it's not found
                    this.userAvatar = await this.images.getForUser(user.id);
                } catch (err) {
                    this.userAvatar = null;
                }
            } else {
                this.user = null;
            }

        });

        // Subscribe to online and offline events to update user notice
        window.addEventListener('online', () => this.updateOfflineStatus(false));
        window.addEventListener('offline', () => this.updateOfflineStatus(true));

        this.on(this.navigation.activeRoot$, root => {
            this.showIE11Message = root === 'login' && (this.deviceService.browser === BROWSERS.IE);

            const page = this.navigation.activePage;
            if (page) {
                const parent = root.split('/')[0];
                this.topNav.update(parent, {});
                this.topNav.activate(parent);
            }
        });

        this.on(this.userNotifications.new$, item => {
            if (this.notificationSound) { this.notificationSound.play(); }
            const details = parseUserNotification(this.i18n, item.eventType, item.data);
            this.snackbar.banner({
                title: details.title,
                description: details.description,
                icon: details.icon,
                actionText: this.i18n.instant('DIALOG.VIEW'),
            }).then(view => {
                if (view) {
                    this.router.navigateByUrl(details.url);
                }
            });
        });

        this.on(this.userNotifications.unseen$, cnt => {
            setTimeout(() => { // fixes a ExpressionChangedAfterItHasBeenCheckedError
                this.unseedNotifications = cnt;
            });
        });

        fromEvent(window, 'resize').pipe(
            takeUntil(this.destroyed),
            debounceTime(100)
        ).subscribe(() => {
            this.updateNavItems();
        });

    }

    updateNavItems() {
        this.groups = this.navigation.getNavigationGroups();

        if (this.sidebarCollapsed && !this.isMobile && this.actionsNav) {
            const container = this.navContainer.clientHeight;
            const actions = this.actionsNav.el.nativeElement.clientHeight;
            const itemHeight = 55; // no need to calculate this programatically
            let visibleLength = Math.ceil(Math.abs(container - actions) / itemHeight); // the length of group.items that will fit into the given space

            const compressedGroups = [];
            const hiddenGroupChildren: NavigationItem[] = [];

            this.groups.forEach(group => {
                const isHidden = group.items.length > visibleLength;

                if (isHidden) {
                    hiddenGroupChildren.push(...group.items);
                } else {
                    compressedGroups.push(group);
                }

                visibleLength -= group.items.length;
            });

            this.groups = !!hiddenGroupChildren.length ? [...compressedGroups, {
                name: null,
                id: 'more-group',
                items: [{
                    name: this.i18n.instant('MENU.MORE'),
                    icon: 'ellipsis-h',
                    id: 'more',
                    group: 'more-group',
                    external: false,
                    children: hiddenGroupChildren,
                }],
            }] : compressedGroups;
        }
    }

    toggleSidebar(opened: boolean) {
        if (!opened) {
            this.mobileActions.toggleSidebar(opened);
        }
    }

    toggleSidebarCollapsed(state: boolean) {
        this.sidebarCollapsed = state;
    }

    doLogout = () => {
        this.metricsService.reset();
        this.auth.logout();
    }

    async gotoClientSelect() {
        if (this.isMobile) {
            if (this.app.user && this.app.user.owner.type !== 'client') {
                this.router.navigate(['client-select', { next: '/status' }]); // always redirect to bare status, other url's will have client specific details in them
            } else {
                this.router.navigate(['/status']);
            }
        } else {
            const result = await SelectionModalComponent.open(this.modal, { id: this.app.user.owner.id, type: 'client' });
            if (result) {
                return this.clientService.loadClient(result.id)
                    .then(() => {
                        this.navigation.activeRoot$.pipe(take(1)).subscribe(root => {
                            this.router.navigate(['/' + root]); // always redirect to bare status, other url's may have client specific details in them
                        });
                    })
                    .catch(err => this.modal.error(err.message, err));
            }
        }
    }

    async switchAccount() {
        const selectedUser = await SelectionModalComponent.open(this.modal, { id: this.app.user.owner.id, type: 'account' });
        if (selectedUser) {
            this.auth.selectAuthenticatedUser(selectedUser.id)
                .then(async user => {
                    this.auth.loadCurrentUser();

                    const root = await this.navigation.activeRoot$.pipe(take(1)).toPromise();

                    if (user.defaultClientId) {
                        return this.clientService.loadClient(user.defaultClientId).then(() => {
                            return this.router.navigate([root]).then(result => {
                                if (!result) { // if this fails (which can happen if a user is attempting to return to a page they no longer have access to, just try go to status again
                                    this.router.navigate(['status']);
                                }
                            });
                        }).catch(err => {
                            this.app.logError(err);
                            this.router.navigate(['client-select', {}]);
                        });
                    } else {
                        this.router.navigate(['client-select', {}]);
                    }


                })
                .catch(err => this.modal.error(err.message, err));
        }
    }

    updateOfflineStatus(offline: boolean) {
        this.errorMessage = null;

        if (offline) {
            this.errorMessage = this.i18n.instant('LOGIN.OFFLINE');
        }
    }

    doHardRefresh() {
        window.location.reload();
    }

    gotoBrowserList() {
        window.open('https://bestvpn.org/outdatedbrowser', '_blank');
    }

    showErrorModal(error: Error | string) {
        const translateMessage = err => {
            let message = (err instanceof Error ? err.message : error) as string;
            if (message.includes('Loading chunk')) {
                message = this.i18n.instant('ERROR.CHUNK_COULD_NOT_LOAD');
            }
            return message;
        };

        this.errorModal.actions = [
            { text: this.i18n.instant('ERROR.ACTIONS.LOGOUT'), style: 'secondary', action: () => { this.doLogout(); } },
            { text: this.i18n.instant('ERROR.ACTIONS.RELOAD'), keypress: 'Enter', action: () => { this.doHardRefresh(); } },
        ];
        this.errorModalInfo = {
            name: error instanceof Error ? error.name || 'Unknown Error' : 'Unknown Error',
            message: translateMessage(error),
        };
        this.errorModal.open();
    }

    showNotification(item: NotificationDetails) {
        this.notification = item;
        if (item.block) {
            this.notifyModal.actions = item.action ? [item.action] : [
                { text: this.i18n.instant('DIALOG.OK'), keypress: 'Enter' },
            ];
            this.notifyModal.title = item.title;
            this.notifyModal.open();
        } else {
            this.snackbar.banner({
                title: item.title,
                description: item.message,
                actionText: item.action ? item.action.text : this.i18n.instant('DIALOG.OK'),
                icon: 'exclamation-triangle',
                keepOpen: true,
            });
        }
    }

    profileAction(action: string) {
        if (action === 'logout') { this.doLogout(); }
        if (action === 'profile') { this.router.navigate(['user', 'profile']); }
        if (action === 'notifications') { this.router.navigate(['user', 'notifications']); }
        if (action === 'client-select') { this.gotoClientSelect(); }
    }

}
