import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input, OnChanges,
    Output,
    ViewChild,
    SimpleChanges,
} from '@angular/core';
import {
    IActionMapping,
    ITreeOptions,
    ITreeState,
    TREE_ACTIONS,
    TreeComponent,
    TreeModel,
    TreeNode,
} from '@ali-hm/angular-tree-component';

export interface KuiTreeSelectNode {
    id: string;
    name: string;
    icon?: string;
    color?: string;
    children?: KuiTreeSelectNode[];
    hasChildren?: boolean;
    getChildren?: (node: KuiTreeSelectNode) => Promise<KuiTreeSelectNode[]>;
    node?: {
        realParent?: {
            [property: string]: any;
        };
        data: {
            [property: string]: any;
        };
    };
    eventName?: string;
    data?: any;
    active?: boolean;
}

const singleSelectActionMap: IActionMapping = {
    mouse: {
        click(tree, node, $event) {
            if (node.hasChildren) {
                TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, event);
            } else {
                TREE_ACTIONS.TOGGLE_ACTIVE(tree, node, $event);
            }
        },
    },
};

/**
 * See tree api
 * https://angular2-tree.readme.io/v7.2.0/docs/treemodel
 */
@Component({
    selector: 'kui-tree-select',
    templateUrl: 'tree-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KuiTreeSelectComponent implements AfterViewInit, OnChanges {
    @Input() singleSelect = false;
    @Input() nodes: KuiTreeSelectNode[];

    @Output() selectedNodeChanged: EventEmitter<KuiTreeSelectNode> = new EventEmitter();

    stateValue: ITreeState;

    @Output() stateChange = new EventEmitter<ITreeState>();
    @Input()
    get state() {
        return this.stateValue;
    }
    set state(value: ITreeState) {
        this.stateValue = value;
        this.stateChange.emit(value);
        this.selectedChange.emit(this.selected);
    }

    @Output() selectedChange = new EventEmitter<string>();
    selectedNodeId: string;
    @Input()
    set selected(value: string) {
        this.selectedNodeId = value;
        setTimeout(() => {
            if (this.treeModel && value) {
                const node = this.treeModel.getNodeById(value);
                if (node) {
                    node.setActiveAndVisible();
                }
            }
        });
    }
    get selected(): string {
        return this.stateValue && this.stateValue.activeNodeIds && Object.keys(this.stateValue.activeNodeIds).filter(x => this.stateValue.activeNodeIds[x])[0];
    }

    @ViewChild('tree', { static: true }) treeComponent: TreeComponent;

    treeModel: TreeModel;
    options: ITreeOptions = {
        getChildren: (node: TreeNode): Promise<KuiTreeSelectNode[]> => {
            if (node.data.getChildren) {
                return node.data.getChildren(node.data);
            }
            return Promise.resolve([]);
        },
    };

    ngAfterViewInit() {
        this.treeModel = this.treeComponent.treeModel;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.singleSelect && this.singleSelect) {
            this.options.actionMapping = singleSelectActionMap;
        }
        if (changes.nodes && Array.isArray(this.nodes)) {
            if (this.nodes.length === 1) {
                setTimeout(() => {
                    this.treeModel.expandAll();
                });
            }
            if (this.selectedNodeId) {
                setTimeout(() => {
                    const node = this.treeModel.getNodeById(this.selectedNodeId);
                    if (node) {
                        node.setActiveAndVisible();
                    }
                });
            }
        }
    }

    clearSelection() {
        this.treeModel.setFocus(false);
        this.treeModel.collapseAll();

        this.treeModel.setState({
            selectedNodeIds: [],
            activeNodeIds: [],
            expandedNodeIds: [],
            selectedLeafNodeIds: [],
            focusedNodeId: null,
        });
    }
}
