<kui-content-header [custom]="true"
    [sub]="true"
    class="datetime-bar no-border-top-nested">

    <div class="flex-stretch d-flex justify-content-center align-items-center">
        <kui-daterange
            #dateRange
            dateFormat="yyyy-MM-dd"
            timeStrategy="day-extents"
            inputStyle="none"
            [timePeriod]="chosenTimePeriod()"
            [min]="minDate()"
            [max]="maxDate()"
            [noDatePlaceholder]="currentString()"
            [timePeriods]="timePeriods"
            (onChange)="handleChange($event)"
        >
        </kui-daterange>
    </div>

    @if (headerActions()) {
        <div class="d-flex justify-content-end pr-1">
            @for (action of headerActions().actions; track action.id) {
                <kui-icon kui-action
                    class="pr-2"
                    [class.active]="action.active"
                    [name]="action.icon"
                    [title]="action.name"
                    (click)="action.click()">
                </kui-icon>
            }
        </div>
    }
</kui-content-header>