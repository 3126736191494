import { Component, Output, EventEmitter, Input, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilderDefinition, FormBuilderFieldChangeEvent, FormBuilderKeyValue, FormBuilderField } from 'app/shared/components/form-builder';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { getLayoutIconSvgString } from './layout.icons';
import * as moment from 'moment-timezone';
import { SettingsDatesMinMax } from 'app/shared/components/analytics/settings/settings.component';
import { TimeOption } from 'app/shared/components/analytics/analytics.model';
import { getAnalyticsTimePresets, shouldTranslateDimension } from '../utils';
import { ModalService } from 'app/shared/components/modal';
import { ListFieldValue } from 'app/shared/components/form-builder/list/list.component';
import { CalculatedMeasure } from '@key-telematics/fleet-api-client';
import * as uuid from 'uuid';
import { MeasurementUnitsService } from 'app/services';

export interface AvailableTableSettings {
    tableLayout?: boolean;
    levels?: boolean;
    flipped?: boolean;
    measures?: boolean;
    daterange?: boolean;
    calcMeasures?: boolean;
}

export interface TableDimensionSetting {
    dim: string;
    level: number;
    levels: {
        name: string;
        headings: string[];
    };
}

export interface TableSettings {
    row: TableDimensionSetting;
    group: TableDimensionSetting;
    flipped: boolean;
    timeOptions: TimeOption[];
    measures: MeasureSettings[];
    dateRange: string;
    dateMinMax: SettingsDatesMinMax;
    layout: string;
    exportOptions?: string[];
    calculatedMeasures?: MeasureSettings[];
}

export interface TableValues {
    rowLevel: string;
    groupLevel: string;
    flipped: boolean;
    timeOptions: TimeOption[];
    dateRange: string;
    dateStart?: string;
    dateEnd?: string;
    measures: MeasureSettings[];
    calculatedMeasures?: MeasureSettings[];
}

export interface CalculatedMeasureSettings {
    calculatedMeasures: MeasureSettings[];
}

export interface MeasureSettings {
    id?: string;
    name?: string;
    expression?: string;
    average?: boolean;
    total?: boolean;
    format?: 'distance' | 'speed' | 'number' | 'integer' | 'duration' | 'consumption' | 'distanceRate' | 'volume';
    value?: string;
    checked?: boolean;
    key?: string;
}

@Component({
    selector: 'key-analytics-settings-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class AnalyticsSettingsTableComponent implements OnChanges, OnInit {
    rowLevels: string[];
    groupLevels: string[];
    dateRange: string[]; // [start, end];

    form: FormBuilderDefinition;
    values: TableValues;
    lastEditedCalcMeasure: MeasureSettings;

    tableLayouts: string[] = ['data', 'pivot'];
    tableLayoutIcons = new Map<string, SafeHtml>();
    tableLayout: string;

    @Input() options: TableSettings;
    @Input() show: AvailableTableSettings = {};
    @Input() allowCustomDates: boolean;
    @Input() singleMeasureSelection: boolean;
    @Output() onChange = new EventEmitter<{ [key: string]: any; }>();
    @Output() onExport = new EventEmitter<string>();

    constructor(
        private i18n: TranslateService,
        private sanitizer: DomSanitizer,
        public modal: ModalService,
        private measurementUnit: MeasurementUnitsService
    ) { }

    ngOnInit() {
        this.tableLayouts.forEach(tableLayout => {
            this.tableLayoutIcons.set(tableLayout, this.sanitizer.bypassSecurityTrustHtml(getLayoutIconSvgString('table-' + tableLayout)));
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        
        if (changes.options && this.options) {
            const { layout, row, group, flipped, timeOptions, measures, dateRange, dateMinMax, calculatedMeasures } = this.options;

            this.rowLevels = row.levels.headings || [];
            this.groupLevels = group.levels.headings || [];

            this.tableLayout = layout;
            this.values = {
                rowLevel: row.level.toString(),
                groupLevel: group.level.toString(),
                flipped,
                timeOptions,
                ...this.getDateRangeFromString(dateRange),
                measures: measures
                    .filter(({ checked }) => checked)
                    .map(x => {
                        if (x.key.includes('calculated_')) {
                            const found = calculatedMeasures.find(measure => measure.id === x.key);
                            const updatedX = {...found, value: x.value };
                            return updatedX;
                        }
                        return { id: x.key, name: x.value, value: x.value };
                    }),
                calculatedMeasures,
            };

            this.form = this.getUpdatedForm(dateMinMax && dateMinMax.min, dateMinMax && dateMinMax.max);

            if (changes.singleMeasureSelection) {
                this.toggleMeasuresDescription(this.singleMeasureSelection);
            }
        }
    }

    changeLayout(layout: string) {
        this.tableLayout = layout;
        this.onChange.emit({ layout });
    }

    changeFormSetting(change: FormBuilderFieldChangeEvent) {
        if (change.dirty) {
            const { id } = change.field;
            const value = this.getValue(id, change.values[id]);

            this.onChange.emit(
                // go through the keys backwards and add each one into its parent
                {
                    ...this.getKey(id).reverse().reduce((res, key) => {
                    return Object.keys(res).length === 0 ? { [key]: value } : { [key]: res };
                    }, {}), 
                    calcMeasureUpdate: this.lastEditedCalcMeasure,
                }
                
            );
            this.lastEditedCalcMeasure = null;
        }
    }

    getLayoutIcon(layout: string): SafeHtml {
        return this.tableLayoutIcons.get(layout);
    }

    getDateRangeFromString(time: string): { dateRange: string; dateStart?: string; dateEnd?: string; } {
        if (time.includes(':')) {
            this.dateRange = time.split(':').map(d => moment(d, 'DD.MM.YYYY').toISOString());
            return {
                dateRange: 'custom',
                dateStart: this.dateRange[0] || moment().startOf('day').toISOString(),
                dateEnd: this.dateRange[1] || moment().endOf('day').toISOString(),
            };
        }
        return { dateRange: time };
    }

    getTimeValueFromDateRange(time: string, dateStart?: string, dateEnd?: string): string {
        if (time !== 'custom') { return time; }

        const start = (moment(dateStart) || moment().startOf('day')).format('DD.MM.YYYY');
        const end = (moment(dateEnd) || moment().endOf('day')).format('DD.MM.YYYY');

        return start + ':' + end;
    }

    getExportIcon(type: string): string {
        switch (type) {
            case 'csv':
                return 'file-spreadsheet';
            case 'pdf':
                return 'file-pdf';
            default:
                return 'file';
        }
    }

    toggleMeasuresDescription(show: boolean) {
        const { fields } = this.form.groups[0];
        const index = fields.findIndex(x => x.id === 'measures');
        fields[index].description = show && this.i18n.instant('ANALYTICS.ERRORS.TOO_MANY_MEASURES');
    }

    getUpdatedForm(minDate: string, maxDate: string): FormBuilderDefinition {
        const min = moment.utc(minDate).toISOString();
        const max = moment.utc(maxDate).toISOString();
        const form = { groups: [{ fields: [] }] };

        const { daterange, flipped, levels, measures, calcMeasures } = this.show;

        if (flipped) {
            form.groups[0].fields.push({ type: 'toggle', id: 'flipped', title: this.translate('FLIPPED') });
        }

        if (levels) {

            const formatLevel = (dim: string, key: string, value: string): {key: string, value: string} => {
                return shouldTranslateDimension(dim) ? { key: key, value: this.translateLevel(dim, value) } : { key: key, value: value };
            };

            const translateDimension = (name: string): string => {
                return this.i18n.instant(`ANALYTICS.DIMENSIONS.${name.toUpperCase().replace(/[^a-zA-Z\d]/g, '_')}`);
            };

            form.groups[0].fields.push({
                type: 'combo', 
                id: 'rowLevel', 
                title: `${translateDimension(this.options.row.dim)} ${this.translate('LEVEL')}`, 
                values: this.rowLevels.map((l, i) => {
                    return formatLevel(this.options.row.dim, i.toString(), l);
                }),
            }, {
                type: 'combo', 
                id: 'groupLevel', 
                title: `${translateDimension(this.options.group.dim)} ${this.translate('LEVEL')}`, 
                values: this.groupLevels.map((l, i)  => {
                    return formatLevel(this.options.group.dim, i.toString(), l);
                }),
            });
        }

        if (daterange) {
            const values: FormBuilderKeyValue[] = getAnalyticsTimePresets(this.options.timeOptions, this.i18n);

            if (this.allowCustomDates) {
                values.push({
                    key: 'custom', value: this.translate('CUSTOM'), fields: [
                        { id: 'dateStart', title: this.translate('FROM_DATE'), type: 'date', min, max },
                        { id: 'dateEnd', title: this.translate('TO_DATE'), type: 'date', min, max },
                    ],
                });
            }

            form.groups[0].fields.push({
                type: 'combo', id: 'dateRange', title: this.translate('TIME'), values,
            });
        }

        if (measures) {
            form.groups[0].fields.push({
                id: 'measures',
                type: 'list',
                title: this.translate('MEASURES'),
                actions: calcMeasures ? ([
                    { id: 'add', name: this.i18n.instant('SHARED.ADD'), click: this.showAddCalcMeasureModal.bind(this), dropdownValues: [
                        ...this.options.measures.filter(v => !v.checked).map(x => x && { id: x.key, name: x.value }), { id: 'custom', name: 'Custom...' },
                        ],
                    },
                    { id: 'edit', click: this.showAddCalcMeasureModal.bind(this), },
                    { id: 'delete', click: async (_action, _field, _items, item) => {
                        const itx = _items.measures.findIndex(x => x.id === item.id);

                        if (itx !== -1) {
                            _items.measures.splice(itx, 1);
                        }
                        return _items;
                    } },
                ]) : [
                    { id: 'add', name: this.i18n.instant('SHARED.ADD'), click: this.showAddCalcMeasureModal.bind(this), dropdownValues: [
                        ...this.options.measures.filter(v => !v.checked).map(x => x && { id: x.key, name: x.value }),
                    ]},
                    { id: 'delete', click: async (_action, _field, _items, item) => {
                        const itx = _items.measures.findIndex(x => x.id === item.id);

                        if (itx !== -1) {
                            _items.measures.splice(itx, 1);
                        }
                        return _items;
                    } },
                ],
                getText: (field: FormBuilderField, values: any) => values[field.id] && values[field.id].map(x => x.name).join(', '),
            });
        }

        return form;
    }

    translate(value: string): string {
        return this.i18n.instant(`ANALYTICS.SETTINGS.${value}`);
    }

    translateLevel(level: string, value: string): string {
        return this.i18n.instant(`ANALYTICS.LEVELS.${level.toUpperCase()}.${value.toUpperCase()}`);
    }

    getValue(id: string, value: any): any {
        if (id === 'measures') {
            const updatedMeasures = this.options.measures.map(measure => ({
                ...measure,
                checked: !!value.find(x => x.value === measure.value) || (measure.key === this.lastEditedCalcMeasure?.id && measure.checked),
            }));
            this.form.groups[0].fields.filter(x => x.id === id).map(x => {
                x.actions[0].dropdownValues = [...updatedMeasures.filter(v => !v.checked).map(x => x && { id: x.key, name: x.value }), { id: 'custom', name: 'Custom...' }];
            });
            return updatedMeasures;
        }

        if (id.includes('Level')) {
            return +value;
        }

        if (id === 'dateRange' || id === 'dateStart' || id === 'dateEnd') {
            const start = this.values['dateStart'] || this.dateRange && this.dateRange[0];
            const end = this.values['dateEnd'] || this.dateRange && this.dateRange[1];
            return this.getTimeValueFromDateRange(this.values['dateRange'], start, end);
        }

        return value;
    }

    async showAddCalcMeasureModal(_action: string, _field: FormBuilderField, values: TableValues, item: any): Promise<CalculatedMeasureSettings> {
        values.calculatedMeasures = values.calculatedMeasures || [];
        
        if (_action === 'custom' || (_action === 'edit' && (item && item.id.includes('calculated_')))) {
            return this.modal.form({
                groups: [{
                    name: this.i18n.instant('ANALYTICS.SETTINGS.CALCULATED_MEASURES'),
                    fields: [
                        {
                            id: 'name', type: 'text', title: this.i18n.instant('ANALYTICS.SETTINGS.NAME'), placeholder: 'Enter measure name', required: true,
                        },
                        {
                            id: 'expression', type: 'text', title: this.i18n.instant('ANALYTICS.SETTINGS.CALCULATION'), placeholder: 'Enter calculation', required: true,
                        },
                        {
                            type: 'toggle', id: 'average', title: this.i18n.instant('ANALYTICS.SETTINGS.AVERAGE'),
                        },
                        {
                            type: 'toggle', id: 'total', title: this.i18n.instant('ANALYTICS.SETTINGS.TOTAL'),
                        },
                        {
                            type: 'combo', id: 'format', title: this.i18n.instant('ANALYTICS.SETTINGS.UNIT'), required: true,
                            values: ['number', 'integer', 'duration'].map((type) => ({
                                key: type, value: this.i18n.instant('ANALYTICS.FORMAT.' + type.toUpperCase(), 
                                { 
                                    unitSymbol: this.measurementUnit.unitSymbol(type),
                                    distanceUnit: this.measurementUnit.unitSymbol('distance'),
                                }),
                            })),
                        },
                    ],
                }],
            }, (item) || {
                name: '',
                expression: '',
                average: false,
                total: false,
                format: '',
            }).then(result => {
                if (result) {
                    this.updateOrAddItem(values.calculatedMeasures, item, result);
                } else {
                    values.calculatedMeasures = this.options.calculatedMeasures;
                }
                return result;
            });
        } else {
            if (_action !== 'edit') {
                const exists = this.options.measures.find(x => x.key === _action);

                if (exists) {
                    values.measures.push({ id: exists.key, value: exists.value, name: exists.value });
                }
            }
        }

    }

    lineItemToListItem(item: any): any {
        return {
            id: item.id || `calculated_${uuid.v4()}`,
            name: item.name,
            expression: item.expression,
            average: item.average,
            total: item.total,
            format: item.format,
            checked: true,
        };
    }

    updateOrAddItem(items: ListFieldValue<CalculatedMeasure>[], existingItem: ListFieldValue<CalculatedMeasure>, item: ListFieldValue<CalculatedMeasure>) {
        if (existingItem) {
            const idx = items.findIndex(x => x.id === existingItem.id);
            items[idx] = this.lineItemToListItem(item);
            const foundIndex = this.options.measures.findIndex(x => x.key === existingItem.id);
            this.options.measures[foundIndex] = {...items[idx], key: items[idx].id, value: items[idx].name };
            this.lastEditedCalcMeasure = items[idx];
        } else {
            const updatedItem = this.lineItemToListItem(item);
            items.push(updatedItem);
            this.options.measures.push({...updatedItem, key: updatedItem.id, value: updatedItem.name, name: updatedItem.name });
            this.lastEditedCalcMeasure = {...updatedItem, added: true };  
        }
    }

    private getKey(id: string): string[] {
        if (id === 'dateStart' || id === 'dateEnd') {
            return ['dateRange'];
        }

        if (id.match(/^(row|group)/)) {
            return id.split(/(row|group)/)
                .filter(x => !!x)
                .map(x => x.toLowerCase());
        }

        return [id];
    }
}
