import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, WritableSignal, computed, signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IKuiModalAction, KuiIconModule, KuiModalModule } from 'app/key-ui';
import { KuiModalRefComponent } from 'app/key-ui/modal/modal-ref.component';
import { MapZonesService } from '../../map/map-zones.service';
import { MapSetService } from '../../map/mapset.service';
import { AssetSelectionService } from '../../selection/services/asset-selection.service';
import { AssetSelectionComponent, VideoAsset } from './asset-selection/asset-selection.component';
import { CameraSelectionComponent } from './camera-selection/camera-selection.component';
import { ClipBuilderComponent, ClipSettings } from './clip-builder/clip-builder.component';
import { LiveCamera, VideoRequestLivestreamComponent } from './livestream-viewer/livestream-viewer.component';
import { VideoRequestSummaryComponent } from './request-summary/request-summary.component';
import { VideoTypeComponent } from './video-type/video-type.component';

enum VideoRequestSteps {
    ASSET_SELECTION = 0,
    CLIP_SETTINGS = 1,
    CAMERA_SELECTION = 2,
    SUMMARY = 3,
    VIDEO_TYPE = 4,
    LIVESTREAM = 5,
}

export enum VideoType {
    CLIP = 0,
    STREAM = 1
}

export interface VideoRequest {
    assetId: string,
    date: string,
    duration: number,
    offset: number,
    cameras: string[]
}

@Component({
    templateUrl: 'request.component.html',
    styleUrls: ['request.component.scss'],
    imports: [
        CommonModule,
        KuiModalModule,
        KuiIconModule,
        TranslateModule,
        ClipBuilderComponent,
        AssetSelectionComponent,
        CameraSelectionComponent,
        VideoRequestSummaryComponent,
        VideoRequestLivestreamComponent,
        VideoTypeComponent
    ],
    providers: [
        AssetSelectionService,
        MapSetService,
        MapZonesService
    ],
    standalone: true,
})
export class RequestComponent extends KuiModalRefComponent<any> implements OnInit {

    step: WritableSignal<number> = signal(0);
    asset: WritableSignal<VideoAsset> = signal<VideoAsset>(undefined);
    clipSettings: WritableSignal<ClipSettings> = signal<ClipSettings>(undefined);
    cameras: WritableSignal<string[]> = signal<string[]>([]);
    liveCameras: WritableSignal<LiveCamera[]> = signal<LiveCamera[]>([]);
    videoType: WritableSignal<VideoType> = signal<VideoType>(undefined);

    readonly Steps = VideoRequestSteps;

    buttons: { [key: string]: IKuiModalAction } = {
        cancel: {
            text: this.i18n.instant('DIALOG.CANCEL'),
            style: 'secondary',
            keypress: 27,
            action: () => {
                this.actions.close();
            },
        },
        back: {
            text: this.i18n.instant('DIALOG.BACK'),
            style: 'secondary',
            action: () => {
                this.previous();
                return true;
            },
        },
        next: {
            text: this.i18n.instant('DIALOG.NEXT'),
            style: 'primary',
            action: () => {
                this.next();
                return true;
            },
        },
        close: {
            text: this.i18n.instant('DIALOG.CLOSE'),
            style: 'primary',
            action: () => {
                this.actions.close();
            },
        },
        finish: {
            text: this.i18n.instant('STATUS.DETAILS.ACTION_BUTTONS.REQUEST_VIDEO'),
            style: 'primary',
            action: () => {
                this.actions.apply({
                    assetId: this.asset().id,
                    duration: this.clipSettings().duration,
                    offset: this.clipSettings().offset,
                    cameras: this.cameras(),
                    date: this.clipSettings().eventDate
                });
                return true;
            },
        },
    };

    modalActions = computed(() => {
        return [
            this.step() !== VideoRequestSteps.LIVESTREAM && this.buttons.cancel,
            this.step() > 0 && this.buttons.back,
            ![VideoRequestSteps.SUMMARY, VideoRequestSteps.LIVESTREAM].includes(this.step()) && this.buttons.next,
            this.step() === VideoRequestSteps.SUMMARY && this.buttons.finish,
            this.step() === VideoRequestSteps.LIVESTREAM && this.buttons.close,
        ].filter(x => x);
    });

    @ViewChild(AssetSelectionComponent, { static: false }) assetSelectionComponent: AssetSelectionComponent;
    @ViewChild(ClipBuilderComponent, { static: false }) clipBuilderComponent: ClipBuilderComponent;
    @ViewChild(CameraSelectionComponent, { static: false }) cameraSelectionComponent: CameraSelectionComponent;
    @ViewChild(VideoTypeComponent, { static: false }) videoTypeComponent: VideoTypeComponent;


    constructor(private i18n: TranslateService, private assetSelectionService: AssetSelectionService) {
        super();
    }

    ngOnInit() {
        if (this.data?.assetId) {
            // Open the wizard to the clip settings after loading asset information
            this.assetSelectionService.getEntity(this.data.assetId).then(asset => {
                this.handleAssetSelected({
                    ...asset,
                    ...this.data.settings
                });

                if (this.liveCameras().length > 0) {
                    // This camera has livestream support, give the user a video type choice
                    this.step.set(VideoRequestSteps.VIDEO_TYPE);
                } else {
                    this.step.set(VideoRequestSteps.CLIP_SETTINGS);
                }
            });
        }
    }

    next(): void {
        if (this.isStepValid(this.step())) {
            switch (this.step()) {
                case VideoRequestSteps.CLIP_SETTINGS:
                    if (this.cameras()?.length > 0 && this.asset()?.cameras?.length === 1) {
                        // Asset has one camera, and it has already been selected, go straight to summary
                        this.step.set(VideoRequestSteps.SUMMARY);
                        break;
                    }
                    this.step.set(VideoRequestSteps.CAMERA_SELECTION);
                    break;
                case VideoRequestSteps.ASSET_SELECTION:
                    if (this.liveCameras().length > 0) {
                        // This camera has livestream support, give the user a video type choice
                        this.step.set(VideoRequestSteps.VIDEO_TYPE);
                        break;
                    }
                    this.step.set(VideoRequestSteps.CLIP_SETTINGS);
                    break;
                case VideoRequestSteps.VIDEO_TYPE:
                    if (this.videoType() === VideoType.CLIP) {
                        this.step.set(VideoRequestSteps.CLIP_SETTINGS);
                        break;
                    }
                    if (this.videoType() === VideoType.STREAM) {
                        this.step.set(VideoRequestSteps.LIVESTREAM);
                        break;
                    }
                default:
                    this.step.update(step => step + 1);
            }
        }
    }

    previous(): void {
        switch (this.step()) {
            case VideoRequestSteps.SUMMARY:
                if (this.asset()?.cameras?.length === 1) {
                    // there's only one camera, skip straight to clip settings
                    this.step.set(VideoRequestSteps.CLIP_SETTINGS);
                    break;
                } else {
                    this.step.update(step => step - 1);
                    break;
                }
            case VideoRequestSteps.CLIP_SETTINGS:
                if (this.liveCameras().length > 0) {
                    // This camera has livestream support, give the user a video type choice
                    this.step.set(VideoRequestSteps.VIDEO_TYPE);
                    break
                }
                this.step.set(VideoRequestSteps.ASSET_SELECTION);
                break;
            case VideoRequestSteps.VIDEO_TYPE:
                this.step.set(VideoRequestSteps.ASSET_SELECTION);
                break;
            default:
                this.step.update(step => step - 1);
        }
    }

    handleAssetSelected(asset: VideoAsset) {
        this.asset.set(asset);

        // asset only has one camera, might as well select it now
        if (asset?.cameras?.length === 1) {
            this.cameras.set([asset.cameras[0].key]);
        }

        this.liveCameras.set(asset?.cameras?.filter(camera => camera?.live).map(camera => {
            return {
                assetId: asset.id,
                cameraId: camera.key,
                cameraName: camera.value
            }
        }));
    }

    handleSettingsChange(settings: ClipSettings) {
        this.clipSettings.set(settings);
    }

    handleCameraSelection(cameras: string[]) {
        this.cameras.set(cameras);
    }

    handleVideoTypeChange(type: VideoType) {
        this.videoType.set(type);
    }

    isStepValid(step: number): boolean {
        switch (step) {
            case VideoRequestSteps.ASSET_SELECTION: {
                return this.assetSelectionComponent.isValid();
            }
            case VideoRequestSteps.CLIP_SETTINGS: {
                return this.clipBuilderComponent.isValid();
            }
            case VideoRequestSteps.CAMERA_SELECTION: {
                return this.cameraSelectionComponent.isValid();
            }
            case VideoRequestSteps.VIDEO_TYPE: {
                return this.videoTypeComponent.isValid();
            }
        }
    }

}
