import { Injectable } from '@angular/core';
import { MapComponent, Point } from 'app/shared/components';
import { getTripIconSvgString } from 'app/shared/components/map/map.markers';
import { TelemetryResponse, IdNameType, EventMedia } from '@key-telematics/fleet-api-client';

import * as moment from 'moment-timezone';
import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { MapService } from './map.service';
import { AppService } from 'app/app.service';
import { flatten } from 'lodash';

export interface MapEventItem {
    id: string;
    owner: IdNameType;
    date: string;
    class: string;
    type: string;
    details: { [key: string]: any; };
    alerts: IdNameType[];
    media?: EventMedia[];
    thumbnail?: string;
}


export interface MapTelemetry extends TelemetryResponse {
    events?: MapEventItem[];
}

export interface MapTripServiceOptions {
    showMarkers: boolean;
    autoZoom: boolean;
}

interface TelemetryTrip {
    id: string;
    telemetry: MapTelemetry[];
}

interface ConnectingPoints {
    firstPoint: Point;
    lastPoint: Point;
    color: string;
}

@Injectable()
export class MapTripService extends MapService {
    public telemetryTrips: TelemetryTrip[] = [];

    public waypoints: MapTelemetry[] = [];
    public selectedTelemetryIndex = -1;
    public playback$ = new Subject<'playing' | 'stopped'>();
    public playbackTimer: number;
    public selectedTelemetry$ = new Subject<MapTelemetry>();

    protected asset: { id: string, color: string };
    protected waypointZoomLevel: number;

    private movement$: Subscription;
    private markerProximity = 25;
    private polylines: string[] = [];

    private selectedTripId: string;
    public selectedTripId$ = new Subject<string>();

    private options: MapTripServiceOptions = {
        showMarkers: true,
        autoZoom: true,
    };

    constructor(app: AppService) {
        super(app);
    }

    public setOptions(options: MapTripServiceOptions) {
        this.options = options;
        this.redrawWaypoints();
    }

    public setTripsTelemetry(trips: { id: string, telemetry: TelemetryResponse[], events?: MapEventItem[] }[]) {
        if (this.map) {
            this.telemetryTrips = [];
            this.telemetryTrips = (trips || []).map(trip => {
                const result = {
                    id: trip.id,
                    telemetry: (trip.telemetry || []).map(tel => ({
                        ...tel,
                        events: trip.events ? trip.events.filter(e => e.date === tel.date) : [],
                    })),
                };
                return result;
            });
            this.redrawWaypoints();
        }
    }

    selectTrip(id: string) {
        this.selectedTripId = id;
        this.redrawWaypoints();
    }

    public redrawWaypoints(reload = true) {
        if (!this.map) { return; }

        if (!reload && this.map.getZoom() === this.waypointZoomLevel) {
            return;
        }

        if (!this.telemetryTrips || this.telemetryTrips.length === 0) {
            return;
        }

        this.clearPolylines();

        // separate line segments per trip and break each one's telemetry up by asset state color
        let selectedGroup = null;
        const groups: { id: string, color: string, points: Point[], tooltip: string, buffer: 0 }[][] = [];
        const tripJoiningData = new Map<moment.Moment, ConnectingPoints>();

        this.telemetryTrips.forEach((trip, index) => {
            groups[index] = [];
            if (trip.id === this.selectedTripId) {
                selectedGroup = groups[index];
            }
            let group = null;
            let prevColor = '';
            trip.telemetry.forEach(tel => {
                if (tel.location) {
                    const state = tel.state && tel.state[Object.keys(tel.state)[0]];
                    const buffer = (state && state['radius']) || 0;
                    const color = (state && state.color) || this.asset?.color || 'blue';
                    if (prevColor !== color) {
                        if (group) {
                            // add this position to the last group too to complete the line
                            group.points.push(this.latLng(tel));
                        }
                        group = { id: trip.id, points: [], color: color, selected: trip.id === this.selectedTripId, tooltip: state?.state, buffer };
                        groups[index].push(group);
                    }
                    group.points.push(this.latLng(tel));
                    prevColor = color;
                }
            });

            // build up data used to construct connecting polylines between starting / ending points of multiple trips
            if (trip.telemetry.length >= 1) {
                const tripDate = this.getMaxDate(trip.telemetry);
                const firstPoint = this.getFirstPoint(trip.telemetry);
                const lastPoint = this.getLastPoint(trip.telemetry);
                const color = this.getPointColor(lastPoint, groups[index]);
                tripJoiningData.set(tripDate, {
                    firstPoint,
                    lastPoint,
                    color,
                });
            }

        });

        const addPolyline = (id, g, selected: boolean) => {
            this.polylines.push(id);
            if (g.buffer > 0) {
                this.map.addPolyline(id, g.points, {
                    color: g.color,
                    weight: selected ? 1 : 0,
                    opacity: selected ? 1 : 0.8,
                    buffer: g.buffer,
                    tooltip: g.tooltip,
                });
            } else {
                this.map.addPolyline(id, g.points, {
                    color: g.color,
                    weight: 6,
                    opacity: selected ? 1 : 0.5,
                    tooltip: g.tooltip,
                });
            }


        };

        // build up a list of points 
        const connectingPoints = this.getConnectingPoints(tripJoiningData);

        connectingPoints.forEach((c, i) => {
            addPolyline(`connecting-line-${i}`, {
                points: c.points,
                color: c.color,
            }, false);
        });

        // filter out the selected items as they are to be drawn later
        groups.filter(x => x !== selectedGroup).forEach((tripGroup, _index) => {
            tripGroup.forEach((g, i) => {
                addPolyline(`${g.id}/${i}`, g, false);
            });
        });

        // finally add the selected item last so it's on top
        groups.filter(x => x === selectedGroup).forEach((tripGroup, _index) => {
            tripGroup.forEach((g, i) => {
                addPolyline(`${g.id}/${i}`, g, true);
            });
        });

        if (reload && this.options.autoZoom) {
            setTimeout(() => {
                const coords = flatten(this.telemetryTrips.map(trip => trip.telemetry.map(this.latLng)));
                if (coords.length > 0) {
                    this.map.zoomToBounds(this.map.getPointBounds(coords));
                }
            });
        }

        if (this.options.showMarkers) {
            this.waypointZoomLevel = this.map.getZoom();

            // save our current waypoint, if selected.
            const currentWaypoint = this.waypoints[this.selectedTelemetryIndex];

            // clear all waypoints before redrawing.
            this.waypoints.forEach(waypoint => this.map.removeMapMarker({id: waypoint.date}));
            
            // display a small subset of all telemetry, to prevent too many markers on the map.
            this.waypoints = flatten(this.telemetryTrips.map(trip => this.deduplicateTelemetry(trip.telemetry)));

            // draw our waypoints
            this.waypoints.forEach((val) => {
                this.drawMarker(val);
            });

            // as we've redrawn the markers, we may have lost our selected telemetry marker, let's redraw it
            if (currentWaypoint) {
                this.selectWaypointTime(currentWaypoint.date, false);
            }
            
        }

    }

    clearPolylines() {
        this.polylines.forEach(id => {
            this.map.removePolyline(id);
        });
        this.polylines = [];
    }

    attachMap(mapComponent: MapComponent) {
        this.detachMap();
        this.map = mapComponent;
        this.movement$ = mapComponent.onMapMoved
            .pipe(
                debounceTime(100)
            )
            .subscribe(() => this.redrawWaypoints(false));

        this.movement$ = mapComponent.onLayerClick
            .subscribe((event) => {
                if (event.layer === 'polyline' && event.id) {
                    this.selectedTripId = event.id.split('/')[0];
                    this.redrawWaypoints(false);
                    this.selectedTripId$.next(this.selectedTripId);
                }
            });
        
    }

    detachMap() {
        if (this.movement$) {
            this.movement$.unsubscribe();
        }

        this.reset();
        this.map = null;
    }

    setAsset(asset: { id: string, color: string }) {
        this.asset = asset;
    }

    stopPlayback() {
        clearInterval(this.playbackTimer);
        this.playback$.next('stopped');
    }

    startPlayback() {
        if (this.selectedTelemetryIndex >= this.waypoints.length - 1) {
            this.selectedTelemetryIndex = 0;
        }

        this.playbackTimer = setInterval(this.seekForward.bind(this), 1000) as any;
        this.playback$.next('playing');
    }

    seekBackward() {
        if (this.selectedTelemetryIndex < 0 && this.playbackTimer) {
            this.stopPlayback();
            this.selectedTelemetryIndex = -1;
        } else {
            this.selectWaypointIndex(this.selectedTelemetryIndex - 1);
        }
    }

    seekForward() {
        if (this.selectedTelemetryIndex >= this.waypoints.length && this.playbackTimer) {
            this.stopPlayback();
            this.selectedTelemetryIndex = this.waypoints.length - 1;
        } else {
            this.selectWaypointIndex(this.selectedTelemetryIndex + 1);
        }
    }

    seekStart() {
        this.selectedTelemetryIndex = 0;
        this.selectWaypointIndex(this.selectedTelemetryIndex);
    }

    seekEnd() {
        this.selectedTelemetryIndex = this.waypoints.length - 1;
        this.selectWaypointIndex(this.selectedTelemetryIndex);
    }

    reset() {
        if (this.playbackTimer) {
            this.stopPlayback();
        }

        this.selectedTelemetryIndex = -1;
        this.telemetryTrips = [];
        this.waypoints = [];

        if (this.map) {
            this.clearPolylines();
            this.map.clearMarkers();
        }
    }

    selectWaypointIndex(index: number, panMap = true) {
        this.selectedTelemetryIndex = index;
        this.map.selectMarker(this.waypoints[index]?.date, panMap);
        this.selectedTelemetry$.next(this.waypoints[index]);
    }

    selectWaypointDate(date: string, panMap = true) {
        this.map.selectMarker(date, panMap);
        this.selectedTelemetryIndex = this.waypoints.findIndex(d => d.date === date);
        this.selectedTelemetry$.next(this.waypoints[this.selectedTelemetryIndex]);
    }

    selectWaypointTime(date: string, panMap = true) {
        if (this.waypoints.length === 0) { return; }

        const presentWaypointIndex = this.waypoints.findIndex(point => point.date === date);

        if (presentWaypointIndex > -1) {
            return this.selectWaypointIndex(presentWaypointIndex, panMap);
        }

        const dateTime = moment(date);
        let insertIndex = this.waypoints.findIndex((point, index, all) => {
            const currentTime = moment(point.date);

            if (index === all.length - 1) {
                return false;
            }

            const nextTime = moment(all[index + 1].date);

            if (currentTime.isBefore(dateTime) && nextTime.isAfter(dateTime)) {
                return true;
            }
        });

        if (insertIndex === -1) {
            if (dateTime.isBefore(this.waypoints[0].date)) {
                insertIndex = 0;
            } else if (dateTime.isAfter(this.waypoints[this.waypoints.length - 1].date)) {
                insertIndex = this.waypoints.length;
            } else {
                throw new Error('The universe has broken 😰');
            }
        } else {
            insertIndex++;
        }

        const item = flatten(this.telemetryTrips.map(x => x.telemetry)).find(tel => tel.date === date);
        if (item) {
            this.drawMarker(item);
            const start = this.waypoints.slice(0, insertIndex);
            const rest = this.waypoints.slice(insertIndex, this.waypoints.length);
            this.waypoints = start.concat([item]).concat(rest);
            this.selectWaypointIndex(insertIndex, panMap);
        }
    }

    drawMarker(telemetry: MapTelemetry) {
        if (telemetry?.location) {
            let radius = 0;
            if (telemetry?.telemetry?.gps_acc !== undefined) {
                radius = telemetry?.telemetry?.gps_acc;
                if (radius < 0) { 
                    radius = 1000; 
                }
            }

            this.map.setMarker({
                id: String(telemetry.date),
                lat: telemetry.location.lat,
                lon: telemetry.location.lon,
                radius: radius / 1000, // convert to km
                getIcon: (selected) => {
                    return telemetry.events[0] ? this.getEventIcon(telemetry.events[0].alerts, selected) : this.getMarkerIcon(telemetry, this.asset?.color || 'blue', selected ? 45 : 30);
                },
                click: () => {
                    this.selectWaypointDate(telemetry.date);
                },
            });
        }
    }

    deduplicateTelemetry(telemetries: TelemetryResponse[]) {
        if (!telemetries) {
            return [];
        }

        const waypoints = [];
        let prev: TelemetryResponse = null;

        for (let i = 0; i < telemetries.length; i++) {
            const current = telemetries[i];
            const proximity = prev ? this.map.getPixelDistance(this.latLng(prev), this.latLng(current)) : 10000;

            if (i === telemetries.length - 1 || proximity >= this.markerProximity) {
                waypoints.push(current);
                prev = current;
            }
        }

        return waypoints;
    }

    latLng(telemetry: TelemetryResponse): Point {
        return {
            y: telemetry.location.lat,
            x: telemetry.location.lon,
        };
    }

    getMarkerIcon(telemetry: TelemetryResponse, color: string, size: number) {
        let iconType = 'idle';

        if (telemetry.active) {
            if (!!telemetry.location && telemetry.location.speed === 0) {
                iconType = 'stop';
            } else {
                iconType = 'direction';
            }
        }

        return {
            size: size,
            html: getTripIconSvgString(this.app.theme, iconType, {
                fill: color,
                size: size,
                rotation: telemetry.location.heading,
            }),
        };
    }

    getEventIcon(alerts: { type?: string, name?: string }[] = [], selected: boolean) {
        const alertType: { [key: string]: { classes: string, shape: 'circle' | 'triangle' } } = {
            low: { classes: 'icon-info-circle text-success', shape: 'circle' },
            medium: { classes: 'icon-exclamation-circle text-warning', shape: 'circle' },
            high: { classes: 'icon-exclamation-triangle text-danger', shape: 'triangle' },
        };

        // any non alert event returns 'low' which is a beautiful green information icon. We can always extend it later to return special icons for specific events like camera and images
        const type = alertType[(alerts.length === 0) ? 'low' : alerts[0].type || 'low'];

        return {
            size: 30,
            html: `
                <div class="map-font-icon ${selected ? 'selected' : ''}">
                    <i class="icon icon__fill ${type.classes}"></i>
                    <i class="icon icon__fill icon-${type.shape} map-font-icon__background map-font-icon__shadows"></i>
                </div>`,
        };
    }


    getTelemetry(date: string): MapTelemetry {
        for (const trip of this.telemetryTrips) {
            const result = trip.telemetry.find(x => x.date === date);
            if (result) {
                return result;
            }
        }
        return null;
    }

    /**
     * Get the connecting points between trips as a 2D array of type `Point`.
     * 
     * Imagine a scenario where we have the following trips (simplified):
     * ```
     * Trip 1: {
     *      date: 2023/02/20
     *      color: green
     *      points: [A, B]
     * }
     * Trip 2: {
     *      date: 2023/02/21
     *      color: red
     *      points: [C, D, E]
     * }
     * Trip 3: {
     *      date: 2023/02/22
     *      color: blue
     *      points: [F, G]
     * }
     * ```
     * 
     * We want to return an array containing the connecting points between trips.
     * This is currently only supported for days immediately following a trip, e.g. Yesterday / Tomorrow
     * 
     * In the above example, the values returned would be:
     * ```
     * [
     *      {
     *          color: green,
     *          points: [B, C]
     *      },
     *      {
     *          color: red,
     *          points: [E, F]
     *      }
     * ]
     * ```
     * 
     * The colour used is from the point furthest in the past, not the most recent one.
     * @param tripJoiningData
     * @returns a 2D array containing points corresponding to start and end points from multiple trips.
     */
    private getConnectingPoints(tripJoiningData: Map<moment.Moment, ConnectingPoints>): { color: string, points: Point[] }[] {
        const connectingPoints: { color: string, points: Point[] }[] = [];

        for (const [currentDate, currentPoints] of tripJoiningData.entries()) {

            // compare our current trip to other trips we're displaying.
            for (const [otherDate, otherPoints] of tripJoiningData.entries()) {

                // calculate the date difference to the nearest day ignoring time.
                const dateDifference = otherDate.diff(currentDate, 'days');

                if (dateDifference < -1 || dateDifference > 1 || dateDifference === 0) {
                    // outside of yesterday / tomorrow or it's today, let's just skip over this record.
                    continue;
                }

                if (dateDifference === 1) {
                    // compared to the current trip date, the compared date is in the future (tomorrow).
                    connectingPoints.push({
                        color: currentPoints.color,
                        points: [currentPoints.lastPoint, otherPoints.firstPoint],
                    });
                }

                if (dateDifference === -1) {
                    // compared to the current trip date, the compared date is in the past (yesterday).
                    connectingPoints.push({
                        color: otherPoints.color,
                        points: [otherPoints.lastPoint, currentPoints.firstPoint],
                    });
                }
            }
        }

        // remove duplicate points from our connecting points array
        const points = connectingPoints.filter(
            (record, index, self) =>
                self.findIndex(
                    (value) => value.points[0] === record.points[0] && value.points[1] === record.points[1]
                ) === index
        );
        return points;
    }

    private getMaxDate(telemetry: MapTelemetry[]): moment.Moment {
        const date = telemetry.map((tel) => new Date(tel.date).getTime());
        return moment(new Date(Math.max(...date))).startOf('day');
    }

    private getFirstPoint(telemetry: MapTelemetry[]): Point {
        if (telemetry.length > 0) {
            return {
                y: telemetry[0].location.lat,
                x: telemetry[0].location.lon,
            };
        }
        return undefined;
    }

    private getLastPoint(telemetry: MapTelemetry[]): Point {
        if (telemetry.length > 0) {
            const lastIndex = telemetry.length - 1;
            return {
                y: telemetry[lastIndex].location.lat,
                x: telemetry[lastIndex].location.lon,
            };
        }
        return undefined;
    }

    private getPointColor(point: Point, groups: { color: string, points: Point[] }[]) {
        let result = 'blue';

        if (groups.length === 1) {
            // there's only one group, use that colour instead
            result = groups[0].color;
        }
        if (groups.length > 1) {
            // try and find the colour of the group this point belongs to...
            groups.forEach(g => {
                if (g.points.find(p => p.x === point.x && p.y === point.y)) {
                    result = g.color;
                }
            });
        }

        return result;
    }
}
