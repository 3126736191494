@for (label of labels(); track label) {
    <span class="badge badge-secondary mt-1 mr-1" 
        [ngClass]="{ 'clickable' : clickable() }"
        (click)="onAction.emit(label.label)">

        <kui-icon name="tag" [style.color]="label.color" class="mr-1"></kui-icon>
        {{ label.name }}

        @if (actionType() === BadgeClickActionType.DELETE) {
           <kui-icon name="times" class="mr-1"></kui-icon>
        }
    </span>
}