import { KuiIconModule } from './../icon/icon.module';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KuiDateTimeComponent } from './datetime.component';

import { CalendarModule } from 'primeng/calendar';
import { KuiActionModule } from 'app/key-ui/action/action.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatchMediaServiceModule } from 'app/services/match-media/match-media.module';
import { DateTimeFormatPipe } from './datetime-format.pipe';

@NgModule({
    imports: [CommonModule, KuiIconModule, FormsModule, CalendarModule, KuiActionModule, TranslateModule, MatchMediaServiceModule],
    exports: [KuiDateTimeComponent],
    declarations: [KuiDateTimeComponent, DateTimeFormatPipe],
    providers: [DateTimeFormatPipe]
})
export class KuiDateTimeModule { }
