<div
  [ngClass]="{
    'd-flex': true,
    'non-resizable-container': !resizable,
    'resizable-container': resizable,
    'resizable-container__right': resizable && direction === 'horizontal',
    'resizable-container__bottom': resizable && direction === 'vertical',
    'flex-row-reverse': direction === 'horizontal' && reverse,
    'flex-column-reverse': direction === 'vertical' && reverse,
  }">
  <ng-content></ng-content>
  <key-layout-grid-column-resizer
    (isSelected)="setDragging($event)"
    (isDoubleClicked)="handleDoubleClick()"
    [ngClass]="{
      'show': resizable,
      'vertical': direction === 'vertical',
      'horizontal': direction === 'horizontal'
    }"></key-layout-grid-column-resizer>
</div>
