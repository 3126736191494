import { Component, Input, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { EMPTY, Observable, combineLatest, from } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../base/base.component';
import { FilterPanelConfig, FilterPanelOperator } from '../../filter-panel.model';
import { FilterPanelService } from '../../filter-panel.service';
import { TreeFilterService, TreeNode } from './tree-filter.service';

@Component({
    selector: 'key-tree-filter',
    styleUrls: ['tree-filter.component.scss'],
    templateUrl: 'tree-filter.component.html',
})
export class KeyTreeFilterComponent extends BaseComponent implements OnInit, OnDestroy {

    @Input() config: FilterPanelConfig;

    loading: WritableSignal<Boolean> = signal(false);

    root: TreeNode = {
        id: 'root',
        data: null,
        indeterminate: false,
        checked: false,
        disabled: false,
        parent: null,
        children: null,
    };

    operator: FilterPanelOperator = 'OR';

    constructor(
        private filters: FilterPanelService,
        private treeService: TreeFilterService,
    ) {
        super();
    }

    ngOnInit() {
        combineLatest([this.filters.filters$, this.getTree()])
            .pipe(takeUntil(this.destroyed))
            .subscribe(([filters, tree]) => {
                this.root = tree;
                this.operator = filters?.operators?.[this.config.id] || 'OR';
                const selectedItems = filters.filters[this.config.id] || [];
                this.treeService.updateTreeSelections(this.root, selectedItems);
            });

        this.treeService.nodeSelected$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.treeService.nodeSelected(res);
                const filterItems = this.treeService.getFilterItems(this.root);
                this.filters.updateFilterValues(this.config.id, [...filterItems]);

            });

        this.treeService.nodeDeselected$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.treeService.nodeDeselected(res);
                const filterItems = this.treeService.getFilterItems(this.root);
                this.filters.updateFilterValues(this.config.id, [...filterItems]);
            });

    }

    ngOnDestroy(): void {
        this.triggerDestroyed();
    }

    private getTree(): Observable<TreeNode> {
        this.loading.set(true);
        return from(this.config.getTreeData())
            .pipe(
                map(data => this.treeService.toTreeNode(data)),
                tap(_ => this.loading.set(false)),
                catchError(err => {
                    console.error(err);
                    this.loading.set(false);
                    return EMPTY;
                })
            );
    }

    updateOperator(operator: FilterPanelOperator): void {
        this.filters.updateOperator(this.config.id, operator);
    }

}
