import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KuiModule } from 'app/key-ui';

import { PipeModule } from 'app/shared/pipes/pipe.module';
import { GetKeyPipe } from 'app/shared/pipes/getkey.pipe';
import { KeyDetailsOverviewCardComponent } from './details-overview-card/details-overview-card.component';
import { KeyLabelsModule } from '../labels/labels.module';


const COMPONENTS = [
    KeyDetailsOverviewCardComponent,
];

@NgModule({
    imports: [
        CommonModule,
        KuiModule,
        TranslateModule.forChild(),
        PipeModule.forRoot(),
        KeyLabelsModule
    ],
    exports: COMPONENTS,
    declarations: COMPONENTS,
    providers: [GetKeyPipe],
})
export class KeyCardsModule { }
